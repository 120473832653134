export default [
    [
        ['abide', '/əˈbaɪd/'],
        ['abode', '/əˈbəʊd/'],
        ['abode', '/əˈbəʊd/']
    ],
    [
        ['arise', '/əˈraɪz/'],
        ['arose', '/əˈrəʊz/'],
        ['arisen', '/əˈrɪz(ə)n/']
    ],
    [
        ['awake', '/əˈweɪk/'],
        ['awoke', '/əˈwəʊk/'],
        ['awoken', '/əˈwəʊ.kən/']
    ],
    [
        ['be', '/bi/'],
        ['was / were', '/wɒz/ /wɜː(r)/'],
        ['been', '/biːn/']
    ],
    [
        ['bear', '/beə(r)/'],
        ['bore', '/bɔː/'],
        ['borne', '/ born /bɔːn/']
    ],
    [
        ['beat', '/bi:t/'],
        ['beat', '/bi:t/'],
        ['beaten', "/'bi:tn/"]
    ],
    [
        ['become', "/bɪ 'kʌm/"],
        ['became', "/bɪ 'keɪm/"],
        ['become', "/bɪ 'kʌm/"]
    ],
    [
        ['beget', '/bɪˈɡet/'],
        ['begat / begot', '/bɪˈɡɒt/'],
        ['begotten', '/bɪˈɡɒt(ə)n/']
    ],
    [
        ['begin', "/bɪ 'gɪn/"],
        ['began', "/bɪ 'gæn/"],
        ['begun', "/bɪ 'gʌn/"]
    ],
    [
        ['bend', '/bend/'],
        ['bent', '/bent/'],
        ['bent', '/bent/']
    ],
    [
        ['bet', '/bet/'],
        ['bet', '/bet/'],
        ['bet', '/bet/']
    ],
    [
        ['bid', '/bɪd/'],
        ['bid / bade', '/bɪd/'],
        ['bid / bidden', '/bɪd/']
    ],
    [
        ['bind', '/baɪnd/'],
        ['bound', '/baʊnd/'],
        ['bound', '/baʊnd/']
    ],
    [
        ['bite', '/baɪt/'],
        ['bit', '/bɪt/'],
        ['bitten', "/'bɪtn/"]
    ],
    [
        ['bleed', '/bli:d/'],
        ['bled', '/bled/'],
        ['bled', '/bled/']
    ],
    [
        ['blow', '/blou/'],
        ['blew', '/blu:/'],
        ['blown', '/bloun/']
    ],
    [
        ['break', '/breɪk/'],
        ['broke', '/brouk/'],
        ['broken', "/'broukәn/"]
    ],
    [
        ['bring', '/brɪŋ/'],
        ['brought', '/brɔ:t/'],
        ['brought', '/brɔ:t/']
    ],
    [
        ['broadcast', '/ˈbrɔːdkɑːst/'],
        ['broadcast', '/ˈbrɔːdkɑːst/'],
        ['broadcast', '/ˈbrɔːdkɑːst/']
    ],
    [
        ['build', '/bɪld/'],
        ['built', '/bɪlt/'],
        ['built', '/bɪlt/']
    ],
    [
        ['burn', '/bɜːn/'],
        ['burnt / burned', '/bɜːnt/'],
        ['burnt / burned', '/bɜːnt/']
    ],
    [
        ['burst', '/bɜːst/'],
        ['burst', '/bɜːst/'],
        ['burst', '/bɜːst/']
    ],
    [
        ['buy', '/baɪ/'],
        ['bought', '/bɔ:t/'],
        ['bought', '/bɔ:t/']
    ],
    [
        ['can', '/kæn/'],
        ['could', '/kʊd/'],
        ['could', '/kʊd/']
    ],
    [
        ['cast', '/kɑːst/'],
        ['cast', '/kɑːst/'],
        ['cast', '/kɑːst/']
    ],
    [
        ['catch', '/kætʃ/'],
        ['caught', '/kɔ:t/'],
        ['caught', '/kɔ:t/']
    ],
    [
        ['chide', '/tʃaɪd/'],
        ['chid / chode', '/tʃɪd/'],
        ['chid / chidden', '/tʃɪd/']
    ],
    [
        ['choose', '/tʃu:z/'],
        ['chose', '/tʃouz/'],
        ['chosen', "/'tʃouzәn/"]
    ],
    [
        ['cling', '/klɪŋ/'],
        ['clung', '/klʌŋ/'],
        ['clung', '/klʌŋ/']
    ],
    [
        ['come', '/kʌm/'],
        ['came', '/keɪm/'],
        ['come', '/kʌm/']
    ],
    [
        ['cost', '/kɒst/'],
        ['cost', '/kɒst/'],
        ['cost', '/kɒst/']
    ],
    [
        ['creep', '/kriːp/'],
        ['crept', '/krept/'],
        ['crept', '/krept/']
    ],
    [
        ['cut', '/kʌt/'],
        ['cut', '/kʌt/'],
        ['cut', '/kʌt/']
    ],
    [
        ['deal', '/diːl/'],
        ['dealt', '/delt/'],
        ['dealt', '/delt/']
    ],
    [
        ['dig', '/dɪg/'],
        ['dug', '/dʌg/'],
        ['dug', '/dʌg/']
    ],
    [
        ['do', '/du:/'],
        ['did', '/dɪd/'],
        ['done', '/dʌn/']
    ],
    [
        ['draw', '/drɔ:/'],
        ['drew', '/dru:/'],
        ['drawn', '/drɔ:n/']
    ],
    [
        ['dream', '/driːm/'],
        ['dreamt / dreamed', '/dremt/'],
        ['dreamt / dreamed', '/dremt/']
    ],
    [
        ['drink', '/drɪŋk/'],
        ['drank', '/dræŋk/'],
        ['drunk', '/drʌŋk/']
    ],
    [
        ['drive', '/draɪv/'],
        ['drove', '/drouv/'],
        ['driven', "/'drɪvәn/"]
    ],
    [
        ['dwell', '/dwel/'],
        ['dwelt', '/dwelt/'],
        ['dwelt / dwelled', '/dwelt/']
    ],
    [
        ['eat', '/i:t/'],
        ['ate', '/eɪt, et/'],
        ['eaten', "/'i:tn/"]
    ],
    [
        ['fall', '/fɔ:l/'],
        ['fell', '/fel/'],
        ['fallen', "/'fɔ:lәn/"]
    ],
    [
        ['feed', '/fi:d/'],
        ['fed', '/fed/'],
        ['fed', '/fed/']
    ],
    [
        ['feel', '/fi:l/'],
        ['felt', '/felt/'],
        ['felt', '/felt/']
    ],
    [
        ['fight', '/faɪt/'],
        ['fought', '/fɔ:t/'],
        ['fought', '/fɔ:t/']
    ],
    [
        ['find', '/faɪnd/'],
        ['found ', '/faʊnd/'],
        ['found', '/faʊnd/']
    ],
    [
        ['flee', '/fliː/'],
        ['fled', '/fled/'],
        ['fled', '/fled/']
    ],
    [
        ['fling', '/flɪŋ/'],
        ['flung', '/flʌŋ/'],
        ['flung', '/flʌŋ/']
    ],
    [
        ['fly', '/flaɪ/'],
        ['flew', '/flu:/'],
        ['flown', '/floun/']
    ],
    [
        ['forbid', '/fəˈbɪd/'],
        ['forbade', '/fə(r)ˈbæd/'],
        ['forbidden', '/fəˈbɪd.ən/']
    ],
    [
        ['forecast', '/ˈfɔːkɑːst/'],
        ['forecast', '/ˈfɔːkɑːst/'],
        ['forecast', '/ˈfɔːkɑːst/']
    ],
    [
        ['forget', "/fər 'get/"],
        ['forgot', "/fәr 'gɒt/"],
        ['forgotten', "/fәr 'gɒtn/"]
    ],
    [
        ['forgive', "/fәr 'gɪv/"],
        ['forgave', "/fər 'geɪv/"],
        ['forgiven', "/fәr 'gɪvən/"]
    ],
    [
        ['forsake', "/fə'seɪk/"],
        ['forsook', "/fə'sʊk/"],
        ['forsaken', "/fə'seɪkn/"]
    ],
    [
        ['freeze', '/friːz/'],
        ['froze', '/frəʊz/'],
        ['frozen', '/ˈfrəʊzn/']
    ],
    [
        ['get', '/get/'],
        ['got', '/gɒt/'],
        ['gotten', '/gɒtn/']
    ],
    [
        ['give', '/gɪv/'],
        ['gave', '/geɪv/'],
        ['given', '/gɪvәn/']
    ],
    [
        ['go', '/gou/'],
        ['went', '/went/'],
        ['gone', '/gɒn/']
    ],
    [
        ['grind', '/ɡraɪnd/'],
        ['ground', '/ɡraʊnd/'],
        ['ground', '/ɡraʊnd/']
    ],
    [
        ['grow', '/grou/'],
        ['grew', '/gru:/'],
        ['grown', '/groun/']
    ],
    [
        ['hang', '/hæŋ/'],
        ['hung', '/hʌŋ/'],
        ['hung', '/hʌŋ/']
    ],
    [
        ['have', '/hæv/'],
        ['had', '/hæd/'],
        ['had', '/hæd/']
    ],
    [
        ['hear', '/hɪәr/'],
        ['heard', '/hɜ:rd/'],
        ['heard', '/hɜ:rd/']
    ],
    [
        ['hide', '/haɪd/'],
        ['hid', '/hɪd/'],
        ['hidden', "/'hɪdn/"]
    ],
    [
        ['hit', '/hɪt/'],
        ['hit', '/hɪt/'],
        ['hit', '/hɪt/']
    ],
    [
        ['hold', '/hould/'],
        ['held', '/held/'],
        ['held', '/held/']
    ],
    [
        ['hurt', '/hɜ:rt/'],
        ['hurt', '/hɜ:rt/'],
        ['hurt', '/hɜ:rt/']
    ],
    [
        ['keep', '/ki:p/'],
        ['kept', '/kept/'],
        ['kept', '/kept/']
    ],
    [
        ['kneel', '/niːl/'],
        ['knelt / knelled', '/nelt/'],
        ['knelt / kneeled', '/nelt/']
    ],
    [
        ['know', '/nou/'],
        ['knew', '/nu:/'],
        ['known', '/noun/']
    ],
    [
        ['lay', '/leɪ/'],
        ['laid', '/leɪd/'],
        ['laid', '/leɪd/']
    ],
    [
        ['lead', '/li:d/'],
        ['led', '/led/'],
        ['led', '/led/']
    ],
    [
        ['lean', '/liːn/'],
        ['leant / leaned', '/lent/'],
        ['leant / leaned', '/lent/']
    ],
    [
        ['leap', '/liːp]/'],
        ['leapt / leaped', '/lept/'],
        ['leapt / leaped', '/lept/']
    ],
    [
        ['learn', '/lɜːn/'],
        ['learnt', '/lɜː(r)nt/'],
        ['learnt', '/lɜː(r)nt/']
    ],
    [
        ['leave', '/li:v/'],
        ['left', '/left/'],
        ['left', '/left/']
    ],
    [
        ['lend', '/lend/'],
        ['lent', '/lent/'],
        ['lent', '/lent/']
    ],
    [
        ['let', '/let/'],
        ['let', '/let/'],
        ['let', '/let/']
    ],
    [
        ['lie', '/laɪ/'],
        ['lay', '/leɪ/'],
        ['lain', '/leɪn/']
    ],
    [
        ['light', '/laɪt/'],
        ['lit / lighted', '/lɪt/'],
        ['lit / lighted', '/lɪt/']
    ],
    [
        ['lose', '/lu:z/'],
        ['lost', '/lɒst/'],
        ['lost', '/lɒst/']
    ],
    [
        ['make', '/meɪk/'],
        ['made', '/meɪd/'],
        ['made', '/meɪd/']
    ],
    [
        ['mean', '/mi:n/'],
        ['meant', '/ment/'],
        ['meant', '/ment/']
    ],
    [
        ['meet', '/mi:t/'],
        [' met', '/met/'],
        ['met', '/met/']
    ],
    [
        ['offset', '/ˈɒfset/'],
        ['offset', '/ˈɒfset/'],
        ['offset', '/ˈɒfset/']
    ],
    [
        ['pay', '/peɪ/'],
        ['paid', '/peɪd/'],
        ['paid', '/peɪd/']
    ],
    [
        ['plead', '/pliːd/'],
        ['pled / pleaded', '/pled/'],
        ['pled / pleaded', '/pled/']
    ],
    [
        ['preset', '/priːˈset/'],
        ['preset', '/priːˈset/'],
        ['preset', '/priːˈset/']
    ],
    [
        ['prove', '/pruːv/'],
        ['proved', '/pruːvd/'],
        ['proven / proved', '/ˈpruːvən/']
    ],
    [
        ['put', '/pʊt/'],
        ['put', '/pʊt/'],
        ['put', '/pʊt/']
    ],
    [
        ['quit', '/kwɪt/'],
        ['quit', '/kwɪt/'],
        ['quit', '/kwɪt/']
    ],
    [
        ['read', '/ri:d/'],
        ['read', '/red/'],
        ['read', '/red/']
    ],
    [
        ['rend', '/rend/'],
        ['rent', '/rent/'],
        ['rent', '/rent/']
    ],
    [
        ['rid', '/rɪd/'],
        ['rid', '/rɪd/'],
        ['rid', '/rɪd/']
    ],
    [
        ['ride', '/raɪd/'],
        ['rode', '/roud/'],
        ['ridden', "/'rɪdn/"]
    ],
    [
        ['ring', '/rɪŋ/'],
        ['rang', '/ræŋ/'],
        ['rung', '/rʌŋ/']
    ],
    [
        ['rise', '/raɪz/'],
        ['rose', '/rouz/'],
        ['risen', "/'rɪzən/"]
    ],
    [
        ['run', '/rʌn/'],
        ['ran', '/ræn/'],
        ['run', '/rʌn/']
    ],
    [
        ['saw', '/sɔː/'],
        ['saw / sawed', '/sɔːd/'],
        ['sawn / sawed', '/sɔːn/']
    ],
    [
        ['say', '/seɪ/'],
        ['said', '/sed/'],
        ['said', '/sed/']
    ],
    [
        ['see', '/si:/'],
        ['saw', '/sɔ:/'],
        ['seen', '/si:n/']
    ],
    [
        ['seek', '/siːk/'],
        ['sought', '/sɔːt/'],
        ['sought', '/sɔːt/']
    ],
    [
        ['sell', '/sel/'],
        ['sold', '/sould/'],
        ['sold', '/sould/']
    ],
    [
        ['send', '/send/'],
        ['sent', '/sent/'],
        ['sent', '/sent/']
    ],
    [
        ['set', '/set/'],
        ['set', '/set/'],
        ['set', '/set/']
    ],
    [
        ['shake', '/ʃeɪk/'],
        ['shook', '/ʃʊk/'],
        ['shaken', "/'ʃeɪkәn/"]
    ],
    [
        ['shed', '/ʃed/'],
        ['shed', '/ʃed/'],
        ['shed', '/ʃed/']
    ],
    [
        ['shine', '/ʃaɪn/'],
        ['shone', '/ʃoun, ʃɒn/'],
        ['shone', '/ʃoun, ʃɒn/']
    ],
    [
        ['shoe', '/ʃuː/'],
        ['shod', '/ʃɒd/'],
        ['shod', '/ʃɒd/']
    ],
    [
        ['shoot', '/ʃu:t/'],
        ['shot', '/ʃɒt/'],
        ['shot', '/ʃɒt/']
    ],
    [
        ['show', '/ʃou/'],
        ['showed', '/ʃoud/'],
        ['shown', '/ʃoun/']
    ],
    [
        ['shrink', '/ʃrɪŋk/'],
        ['shrank', '/ʃræŋk/'],
        ['shrunk', '/ʃrʌŋk/']
    ],
    [
        ['shut', '/ʃʌt/'],
        ['shut', '/ʃʌt/'],
        ['shut', '/ʃʌt/']
    ],
    [
        ['sing', '/sɪŋ/'],
        ['sang', '/sæŋ/'],
        ['sung', '/sʌŋ/']
    ],
    [
        ['sink', '/sɪŋk/'],
        ['sank / sunk', '/sæŋk/'],
        ['sunk / sunken', '/sʌŋk/']
    ],
    [
        ['sit', '/sɪt/'],
        ['sat', '/sæt/'],
        ['sat', '/sæt/']
    ],
    [
        ['slay', '/sleɪ/'],
        ['slew', '/sluː/'],
        ['slain', '/sleɪn/']
    ],
    [
        ['sleep', '/sli:p/'],
        ['slept', '/slept/'],
        ['slept', '/slept/']
    ],
    [
        ['slide', '/slaɪd/'],
        ['slid', '/slɪd/'],
        ['slid', '/slɪd/']
    ],
    [
        ['slit', '/slɪt/'],
        ['slit', '/slɪt/'],
        ['slit', '/slɪt/']
    ],
    [
        ['smell', '/smel/'],
        ['smelt', '/smelt/'],
        ['smelt', '/smelt/']
    ],
    [
        ['sow', '/səʊ/'],
        ['sowed', '/səʊd/'],
        ['sown', '/ sowed /səʊn/']
    ],
    [
        ['speak', '/spi:k/'],
        ['spoke', '/spouk/'],
        ['spoken', "/'spoukən/"]
    ],
    [
        ['speed', '/spiːd/'],
        ['sped', '/sped/'],
        ['sped', '/sped/']
    ],
    [
        ['spell', '/spel/'],
        ['spelt', '/spelt/'],
        ['spelt', '/spelt/']
    ],
    [
        ['spend', '/spend/'],
        ['spent', '/spent/'],
        ['spent', '/spent/']
    ],
    [
        ['spill', '/spɪl/'],
        ['spilt / spilled', '/spɪlt/'],
        ['spilt / spilled', '/spɪlt/']
    ],
    [
        ['spin', '/spɪn/'],
        ['spun', '/spʌn/'],
        ['spun', '/spʌn/']
    ],
    [
        ['spit', '/spɪt/'],
        ['spat / spit', '/spæt/'],
        ['spat / spit', '/spæt/']
    ],
    [
        ['split', '/splɪt/'],
        ['split', '/splɪt/'],
        ['split', '/splɪt/']
    ],
    [
        ['spoil', '/spɔɪl/'],
        ['spoilt', '/spɔɪlt/'],
        ['spoilt', '/spɔɪlt/']
    ],
    [
        ['spread', '/spred/'],
        ['spread', '/spred/'],
        ['spread', '/spred/']
    ],
    [
        ['spring', '/sprɪŋ/'],
        ['sprang', '/spræŋ/'],
        ['sprung', '/sprʌŋ/']
    ],
    [
        ['stand', '/stænd/'],
        ['stood', '/stʊd/'],
        ['stood', '/stʊd/']
    ],
    [
        ['steal', '/sti:l/'],
        ['stole', '/stoul/'],
        ['stolen', "/'stoulәn/"]
    ],
    [
        ['stick', '/stɪk/'],
        ['stuck', '/stʌk/'],
        ['stuck', '/stʌk/']
    ],
    [
        ['sting', '/stɪŋ/'],
        ['stung', '/stʌŋ/'],
        ['stung', '/stʌŋ/']
    ],
    [
        ['stink', '/stɪŋk/'],
        ['stank', '/stæŋk/'],
        ['stunk', '/stʌŋk/']
    ],
    [
        ['strew', '/struː/'],
        ['strewed', '/struːd/'],
        ['strewn / strewed', '/struːn/']
    ],
    [
        ['strike', '/straɪk/'],
        ['struck', '/strʌk/'],
        ['stricken / struck', '/strʌk/']
    ],
    [
        ['strive', '/straɪv/'],
        ['strove', '/strəʊv/'],
        ['striven', '/ˈstrɪv(ə)n/']
    ],
    [
        ['swear', '/sweə/'],
        ['swore', '/swɔːr/'],
        ['sworn', '/swɔːn/']
    ],
    [
        ['sweat', '/swet/'],
        ['sweat / sweated', '/swet/'],
        ['sweat / sweated', '/swet/']
    ],
    [
        ['sweep', '/swiːp/'],
        ['swept', '/swept/'],
        ['swept', '/swept/']
    ],
    [
        ['swell', '/swel/'],
        ['swelled', '/sweld/'],
        ['swollen', '/ˈswəʊlən/']
    ],
    [
        ['swim', '/swɪm/'],
        ['swam', '/swæm/'],
        ['swum', '/swʌm/']
    ],
    [
        ['swing', '/swɪŋ/'],
        ['swung', '/swʌŋ/'],
        ['swung', '/swʌŋ/']
    ],
    [
        ['take', '/teɪk/'],
        ['took', '/tʊk/'],
        ['taken', "/'teɪkәn/"]
    ],
    [
        ['teach', '/ti:tʃ/'],
        ['taught', '/tɔ:t/'],
        ['taught', '/tɔ:t/']
    ],
    [
        ['tear', '/teәr/'],
        ['tore', '/tɔr/'],
        ['torn', '/tɔrn/']
    ],
    [
        ['tell', '/tel/'],
        ['told', '/tould/'],
        ['told', '/tould/']
    ],
    [
        ['think', '/θɪŋk/'],
        ['thought', '/θɔ:t/'],
        ['thought', '/θɔ:t/']
    ],
    [
        ['thrive', '/θraɪv/'],
        ['throve / thrived', '/θrəʊv/'],
        ['thriven / thrived', '/ˈθrɪvən/']
    ],
    [
        ['throw', '/θrou/'],
        ['threw', '/θru:/'],
        ['thrown', '/θroun/']
    ],
    [
        ['thrust', '/θrʌst/'],
        ['thrust', '/θrʌst/'],
        ['thrust', '/θrʌst/']
    ],
    [
        ['typeset', '/ˈtaɪpset/'],
        ['typeset', '/ˈtaɪpset/'],
        ['typeset', '/ˈtaɪpset/']
    ],
    [
        ['undergo', '/ʌndəˈɡəʊ/'],
        ['underwent', '/ʌndəˈwent/'],
        ['undergone', '/ˌʌndəˈɡɒn/']
    ],
    [
        ['understand', "/ʌndәr 'stænd/"],
        ['understood', "/ʌndәr 'stʊd/"],
        ["understood", "/ʌndәr 'stʊd/"]
    ],
    [
        ['wake', '/weɪk/'],
        ['woke', '/wouk/'],
        ['woken', "/'woukәn/"]
    ],
    [
        ['wear', '/weәr/'],
        ['wore', '/wɔr/'],
        ['worn', '/wɔrn/']
    ],
    [
        ['weep', '[wiːp]'],
        ['wept', '[wept]'],
        ['wept', '[wept]']
    ],
    [
        ['wet', '[wet]'],
        ['wet / wetted', '[wet]'],
        ['wet / wetted', '[wet]']
    ],
    [
        ['win', '/wɪn/'],
        ['won', '/wʌn/'],
        ['won', '/wʌn/']
    ],
    [
        ['wind', '[wɪnd]'],
        ['wound', '[wuːnd]'],
        ['wound', '[wuːnd]']
    ],
    [
        ['withdraw', '/wɪðˈdrɔː/'],
        ['withdrew', '/wɪðˈdruː/'],
        ['withdrawn', '/wɪðˈdrɔːn/']
    ],
    [
        ['wring', '[rɪŋ]'],
        ['wrung', '[rʌŋ]'],
        ['wrung', '[rʌŋ]']
    ],
    [
        ['write', '/raɪt/'],
        ['wrote', '/rout/'],
        ['written', "/'rɪtn/"]
    ],
]

