<template>
  <div class="navigation-bar">
    <div class="navigation-pad">
      <b-row class="align-items-center">
        <b-col>
          <b-dropdown menu-class="noscrollbar" right no-caret variant="link" class="d-inline-block pointer">
            <template v-slot:button-content>
              <button class="btn-themed btn-white">
                {{ $t('all_slides') }}<UpSvg class="ml-2"/>
              </button>
            </template>
            <b-dropdown-item v-for="(slid, sind) in slides"
                             :key="'slidecounter'+sind"
                             @click="toslide(sind)">
              {{ sind + 1}}. {{ slid.title }}
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown menu-class="noscrollbar"
                      right
                      no-caret
                      variant="link"
                      class="d-inline-block pointer ml-2"
                      v-if="!homework && changeModes">
            <template v-slot:button-content>
              <button class="btn-themed btn-white">
                {{ $t(slidemode) }}<UpSvg class="ml-2"/>
              </button>
            </template>
            <b-dropdown-item :class="{ active: slidemode == 'lesson' }" @click="$emit('slidemodechange','lesson')" >{{ $t('lesson') }}</b-dropdown-item>
            <b-dropdown-item :class="{ active: slidemode == 'homework' }" @click="$emit('slidemodechange','homework')">{{ $t('homework') }}</b-dropdown-item>
          </b-dropdown>
        </b-col>
        <b-col cols="auto" class="left-right-buttons" v-if="slides">
          <button v-if="homework && slide == (slides.length - 1)" @click="finishHomework" class="btn btn-themed mr-3">
            {{ $t('finish') }}
          </button>
          <button class="btn-themed btn-white" :disabled="slide < 1" @click="previousslide">
            <LeftSvg/>
          </button>
          <button class="ml-2 btn-themed btn-white" :disabled="slide >= (slides.length - 1)" @click="nextslide">
            <RightSvg/>
          </button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import UpSvg from "@/assets/svg/up.svg"
import RightSvg from "@/assets/svg/right.svg"
import LeftSvg from "@/assets/svg/left.svg"

export default {
  components: {
    LeftSvg,
    RightSvg,
    UpSvg
  },
  data() {
    return {

    }
  },
  props: {
    slides: {},
    slide: {},
    slidemode: {},
    homework: { type: Boolean },
    changeModes: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    nextslide() {
      if(!this.slides) return
      let slide = Math.min((this.slides.length - 1), (this.slide + 1))
      this.$emit('slidechanged',slide)
    },
    previousslide() {
      let slide = Math.max(0, (this.slide - 1))
      this.$emit('slidechanged',slide)
    },
    toslide(val) {
      if(!this.slides) return
      let slide = Math.min((this.slides.length - 1), val)
      this.$emit('slidechanged',slide)
    },
    finishHomework() {
      this.$emit('finishhomework')
    }
  },
}
</script>

<style scoped lang="scss">

.navigation-bar {
  padding-top: 16px;
  padding-bottom: 8px;
  bottom: 0;
  z-index: 10;
  .left-right-buttons {
    svg {
      stroke: #000;
      stroke-width: 2px;
    }
  }
  .btn-themed {
    margin-bottom: 8px;
    vertical-align: middle;
  }
}

</style>






