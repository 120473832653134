<template>
  <vue-resizable :left="isMobile ? 0 : 72"
                 :disableAttributes="[]"
                 :minHeight="300"
                 dragSelector=".drag-part">
    <div id="irregularVerbsModal" class="spacecard">
      <div>
        <div class="drag-part">
          <b-row class="align-items-center">
            <b-col>
              <h2 class="mb-0">{{ $t('irregular_verbs') }}</h2>
            </b-col>
            <b-col cols="auto">
              <a class="pointer position-relative modal-close call_room_btn" 
                 @click="$parent.verbsShow = false">
                <CloseSvg/>
              </a>
            </b-col>
          </b-row>
        </div>
        <div class="search-input-wrapper mt-3">
          <SearchSvg class="position-absolute search-icon"/>
          <input class="search-form-control"
                 @input="search"
                 :placeholder="$t('search')"
                 v-model="q"/>
        </div>
        <div class="scroll-content mt-4">
          <table class="table table-striped">
            <tbody>
            <tr v-for="(verb, vind) in currentVerbs" :key="`tr-${vind}`">
              <td v-for="(form, find) in verb" :key="`tr-${vind}-${find}`">
                <span>{{ form[0] }}</span>
                <span class="text-muted ml-2">{{ form[1] }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </vue-resizable>
</template>

<script>

import Irregulars from '@/dictionaries/irregular_verbs'
import VueResizable from 'vue-resizable'
import CloseSvg from '@/assets/svg/close_gray.svg'
import SearchSvg from '@/assets/svg/search.svg'
import {mapState} from "vuex"

export default {
  name: "IrregularVerbsModal",
  components: {
    VueResizable,
    CloseSvg,
    SearchSvg
  },
  props: {
    shown: {}
  },
  computed: {
    ...mapState({
      isMobile: state => state.isMobile,
    }),
    windowW() {
      return window.innerWidth
    },
    windowH() {
      return window.innerHeight
    }
  },
  data() {
    return {
      irregularVerbs: Irregulars,
      currentVerbs: [],
      q: ''
    }
  },
  methods: {
    search() {
      this.currentVerbs = this.irregularVerbs.filter(x => {
        return x[0][0].includes(this.q) || x[1][0].includes(this.q) || x[2][0].includes(this.q)
      })
    }
  },
  mounted() {
    this.currentVerbs = this.irregularVerbs
  }
}
</script>

<style scoped lang="scss">
#irregularVerbsModal {
  width: 600px;
  .scroll-content {
    overflow-y: scroll;
    max-height: 300px;
  }
}
.table-striped tbody {
  tr:nth-of-type(odd) {
    background-color: #F9F9FB;
  }
  td {
    border-top: none;
    border-right: 1px solid #E6EAF2;
    &:last-of-type {
      border-right: none;
    }
  }
}
@media(max-width: 768px) {
  #irregularVerbsModal {
    width: 100vw;
    .scroll-content {
      max-height: calc(100vh - 200px);
    }
  }
  table {
    td {
      display: block;
      padding: 0.5rem;
    }
  }
  .spacecard {
    border-radius: 0;
    border: none;
    height: calc(100vh - 64px);
  }
}
</style>
