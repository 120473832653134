<template>
  <div class="position-relative" :class="[isChat ? 'forChat' : '']">
    <input type="text"
           class="form-control form-control-white padded showplaceholder"
           @blur="clickoutside"
           @focus="initFocus"
           v-model="q"
           :placeholder="$t('word')"/>
    <div class="search-block space-dropdown" v-if="showDropdown && q" v-click-outside="clickoutside">
      <WordTeaser v-for="(f, find) in foundWords"
                  :key="'found-word-'+find"
                  :audio="false"
                  @mousedown.native="selectWord(f)"
                  :word="f"
                  class="position-relative word-searched pointer">
      </WordTeaser>
      <div class="word-searched add-own-version border-bottom small text-muted" v-if="foundWords.length == 0">{{ $t('nothing_found') }}</div>
      <a class="text-underlined add-own-version pointer small text-muted" @mousedown="addOwn">{{ $t('add_own_version') }}</a>
    </div>
  </div>
</template>

<script>
// import RefreshSvg from '@/assets/svg/refresh.svg'
import WordTeaser from '@/views/teasers/WordTeaser'
import { VocabularyService } from "@/services/api.service"

export default {
  components: {
    WordTeaser
  },
  data() {
    return {
      showDropdown: false,
      q: '',
      foundWords: [],
      timer: null
    }
  },
  props: {
  },
  watch: {
    q() {
      if(this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.search()
      }, 250)
    }
  },
  mounted() {},
  methods: {
    search() {
      if(!this.q) {
        return
      }
      VocabularyService.searchForWord(this.q, false).then(res => {
        console.log(res.data.data)
        this.foundWords = res.data.data.data
      })
    },
    initFocus() {
      this.showDropdown = true
      this.search()
    },
    clickoutside() {
      // this.showDropdown = false
      this.q = ""
    },
    selectWord(word) {
      this.$emit('selected', word)
      this.q = ""
    },
    addOwn() {
      let word = this.q
      this.$input(this.$t('translation'), this.$t('translation')+': '+word, "").then((val) => {
        let params = {
          word: word,
          translation_ru: val
        }
        this.$emit('selected', params)
        this.q = ""
      })
    }
  },
  computed: {
    isChat() {
      return this.$route.name !== 'StudentVocabulary';
    }
  }
}
</script>

<style scoped lang="scss">

.forChat {
  margin: 5px 0;
  input {
    height: 60px;
    border: none;
    background: #F8F9FB;

    &:focus {
      box-shadow: none;
    }
  }
}

.position-relative {
  position: relative;
  z-index: 6;
}

.space-dropdown {
  padding: 8px;
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  .add-own-version {
    padding: 16px 16px;
    display: block;
  }
}
</style>


