const KEY_ADDITION = "task-"
import router from '@/router/index'
import { LessonsService } from '@/services/api.service'

export const get = (id) => {
    let d = window.localStorage.getItem(getKey(id))
    if(d) return JSON.parse(d)
    return null
}

export const save = (id, results) => {
    let res = JSON.stringify(results)
    window.localStorage.setItem(getKey(id), res)
    LessonsService.saveTaskStorage({
        lesson_id: getLessonId(),
        block_id: id,
        data: results
    })
}

export const remove = (id) => {
    window.localStorage.removeItem(getKey(id))
}

export const getLessonHomework = (lesson_id) => {
    let arr = {}
    for (let key in window.localStorage) {
        if(key.startsWith(KEY_ADDITION+lesson_id.toString()+'-')) {
            arr[key.replace(KEY_ADDITION+lesson_id.toString()+'-', '')] = window.localStorage.getItem(key)
        }
    }
    return arr
}

export const uploadReceivedHomeworkToLocalStorage = (homework) => {
    if(!homework) return
    for(const task_id in homework) {
        save(task_id, JSON.parse(homework[task_id]))
    }
}


const getLessonId = () => {
    const route = router.app.$route
    if(route.name === 'Lesson') {
        return route.params.id
    }
    return null
}

const getKey = (task_id) => {
    const lesson_id = getLessonId()
    if(lesson_id) {
        return KEY_ADDITION+lesson_id.toString()+'-'+task_id.toString()
    }
    return KEY_ADDITION+task_id.toString()
}

export default {
    get,
    save,
    remove,
    getLessonHomework,
    uploadReceivedHomeworkToLocalStorage
}
