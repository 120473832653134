<template>
  <div id="canvaswrapper" :class="{ isKeyboardOpen: isKeyboardOpen }">

    <MirotalkBlackboard v-show="lessonModesEnabled.includes('blackboard')"/>

    <div class="slide"
         id="slide"
         v-if="lessonModesEnabled.includes('slide')"
         :class="{
           half: lessonModesEnabled.length === 1 && !lessonModeFull,
           'border-right': lessonModesEnabled.length === 1 && homework,
           halfer: lessonModesEnabled.length === 2,
           toRight: lessonModesEnabled.length === 2 && lessonModesEnabled.indexOf('slide') == 1,
           withoutVideo: view === 'selfstudy' || homework
         }"
    >
      <div class="border-bottom slide-heading">
        <div class="w-100">
          <b-row class="align-items-center">
            <b-col class="d-md-block d-none">
              <div class="slide-material" @click="materialClicked">
                <div class="d-flex align-items-center">
                  <LetterIcon :obj="material"
                              v-if="material"
                              :title="material.title"
                              class="student-icon sm d-inline-block"/>
                  <div class="d-inline-block ml-2 slide-material-text" v-if="material">
                    <div class="title">{{ material.title }}</div>
                    <div class="level" v-if="material.level">{{ material.level.title }}</div>
                  </div>
                  <div v-if="!material && host">{{ $t('add_material') }}</div>
                </div>
              </div>
            </b-col>
            <b-col md="col"
                   cols="3"
                   class="text-md-center text-left nowrap">
              <a class="slide-switcher border-bottom-0 d-inline-flex d-md-none"
                 @click="materialClicked"
                 v-if="host">
                <LetterIcon :obj="material"
                            v-if="material"
                            :title="material.title"
                            class="student-icon xs"
                            style="margin-right: 12px; position: relative; bottom: 3px;"/>
                <div v-if="!material && host">{{ $t('add_material') }}</div>
              </a>
              <a class="slide-switcher"
                 v-if="material"
                 :class="{ active: slidemode === 'lesson' }"
                 @click="slidemodechange('lesson')">
                <span class="d-md-inline d-none">{{ $t('lesson') }}</span>
                <SlidesSvg class="d-md-none d-inline"
                           :class="slidemode === 'lesson' ? 'svg-blue' : 'svg-gray'"/>
              </a>
              <a class="slide-switcher ml-3"
                 v-if="material"
                 :class="{ active: slidemode === 'homework' }"
                 @click="slidemodechange('homework')">
                <span class="d-md-inline d-none">{{ $t('homework') }}</span>
                <HomeSvg class="d-md-none d-inline"
                         :class="slidemode === 'homework' ? 'svg-blue' : 'svg-gray'"/>
              </a>
            </b-col>
            <b-col class="d-md-none d-block">
              <div class="text-center" v-if="material">
                <a class="slide-nav-btn" @click="previousslide"><LeftSvg/></a>
                <span class="current-slide">{{ (currentSlide + 1) }} / {{ currentSlides.length }}</span>
                <a class="slide-nav-btn" @click="nextslide"><RightSvg/></a>
              </div>
            </b-col>
            <b-col md cols="3" class="text-right">
              <button class="call_room_btn"
                      @click="$store.commit('expandLessonMode', 'slide')">
                <ExpandSvg v-if="!lessonModeFull"/><CollapseSvg v-if="lessonModeFull"/>
              </button>
              <button class="call_room_btn ml-2 d-md-inline d-none"
                      v-if="!lessonModeFull && lessonModesEnabled.length > 1"
                      @click="$store.commit('switchSideLessonMode', 'slide')">
                <SwitchSvg/>
              </button>
              <button class="call_room_btn ml-2"
                      @click="$store.commit('toggleLessonMode', 'slide')">
                <CloseSvg/>
              </button>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="p-3 canvas-content" v-if="!finishedLesson">
        <h3 class="mb-1" v-if="currentSlides[currentSlide]">{{ currentSlides[currentSlide].title }}</h3>
        <TaskComponentsParser v-if="currentSlides[currentSlide] && currentSlides[currentSlide].components"
                              class="slidehtml"
                              :slide="currentSlide"
                              :slidecomponents="currentSlides[currentSlide].components"
                              :selectedUser="selectedUser"
                              :homeworkStudentId="homeworkStudentId"
                              :host="host"
                              :view="view"
                              :followed="followed"
                              source="class"
                              @slidesolved="slideSolved(currentSlides[currentSlide].id, ...arguments)"
                              :key="'slide-html-'+currentSlide+'-'+slidemode"
                              v-images-loaded="htmlimagesloaded"/>
        <div v-if="currentSlides.length">
          <hr class="mt-5"/>
          <div class="text-center">
            <a class="slide-nav-btn" @click="previousslide"><LeftSvg/></a>
            <span class="current-slide">{{ (currentSlide + 1) }} / {{ currentSlides.length }}</span>
            <a class="slide-nav-btn" @click="nextslide"><RightSvg/></a>
          </div>
        </div>

      </div>

      <transition name="fade" v-if="finishedLesson">
        <LessonFinished :material="material"
                        :view="view"
                        @finishedClick="finisedLesson"
                        :host="host"/>
      </transition>

    </div>

    <WordCardsLessonPart :view="view"
                         v-if="lessonModesEnabled.includes('cards')"
                         :cards="cards"
                         @done="wordsCardsDone"/>
  </div>
</template>

<script>

import imagesLoaded from 'vue-images-loaded'
import CloseSvg from "@/assets/svg/close_gray.svg"
import SwitchSvg from "@/assets/svg/switch-sides.svg"
import ExpandSvg from "@/assets/svg/expand.svg"
import CollapseSvg from "@/assets/svg/collapse.svg"
import SlidesSvg from "@/assets/svg/slides.svg"
import HomeSvg from "@/assets/svg/home.svg"
import RightSvg from "@/assets/svg/right_s.svg"
import LeftSvg from "@/assets/svg/left_s.svg"
import LetterIcon from '@/views/parts/general/LetterIcon'
import { mapState } from 'vuex'
import LessonFinished from "../LessonFinished";
import TaskComponentsParser from "@/views/parts/class/preview/TaskComponentsParser"
import { getSlidesSolved, saveSlidesSolved} from "../../../services/slide-storage"
import WordCardsLessonPart from "./WordCardsLessonPart"
import MirotalkBlackboard from "./MirotalkBlackboard"

export default {
  components: {
    MirotalkBlackboard,
    WordCardsLessonPart,
    SwitchSvg,
    SlidesSvg,
    HomeSvg,
    CloseSvg,
    ExpandSvg,
    CollapseSvg,
    RightSvg,
    LeftSvg,
    LessonFinished,
    TaskComponentsParser,
    LetterIcon
  },
  directives: {
    imagesLoaded
  },
  data() {
    return {
      slidewidth: 0,
      slidesSolved: {},
      peers: {},
    }
  },
  props: {
    config: {},
    lesson: {},
    usersdata: {},
    userId: {},
    homeworkStudentId: {},
    view: { type: String, default: 'lesson' },
  },
  methods: {
    openBurger() {
      if (!this.bottomBurger) {
        this.$store.commit('setBottomBurger', true)
      } else {
        this.$parent.mobileChatShow = false
        this.$store.commit('setBottomBurger', false)
      }
    },
    finisedLesson() {
      if(this.homework) {
        this.$emit('homeworkdone')
      } else {
        this.$emit('stop')
      }
    },
    htmlimagesloaded() {
      //   this.canvasInit()
    },
    nextslide() {
      if (this.currentSlide === (this.currentSlides.length - 1)) {
        this.$store.commit('setFinishedLesson', true)
      }
      this.$store.commit('setSpecificSlide', Math.min((this.currentSlides.length - 1), (this.currentSlide + 1)))
      this.changeSlide()
    },
    previousslide() {
      if (this.finishedLesson) {
        this.$store.commit('setFinishedLesson', false)
        this.changeSlide()
        return
      }
      this.$store.commit('setSpecificSlide', Math.max(0, (this.currentSlide - 1)))
      this.changeSlide()
    },
    toslide(val) {
      this.$store.commit('setSpecificSlide', Math.min((this.currentSlides.length - 1), val))
      this.changeSlide()
    },
    changeSlide() {
      if (this.homework) {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        return
      }
      if (this.host || this.followed) {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        if(this.socket) {
          this.socket.emit('customevent', 'slidechanged', this.room, this.slide, this.finishedLesson)
        }
      }
    },
    slidemodechange(mode) { // between homework and lesson
      this.$store.commit('setSlideMode', mode)
      if (this.finishedLesson && this.slidemode === 'homework') {
        this.$store.commit('setFinishedLesson', false)
      }
      if (this.host && this.socket) {
        this.socket.emit('customevent', 'slidemodechanged', this.room, this.slidemode)
      }

    },
    followMe() {
      if(this.socket) {
        this.socket.emit('customevent', 'slidemodechanged', this.room, this.slidemode)
        this.socket.emit('customevent', 'slidechanged', this.room, this.slide)
        // this.socket.emit('customevent', 'blackboardmodechanged', this.room, this.mode)
      }
    },
    sendDataThroughPeerConnections(data) {
      for (let prop in this.usersdata) {
        if (Object.prototype.hasOwnProperty.call(this.usersdata, prop)) {
          if (prop == this.userId) return
          let u = this.usersdata[prop]
          if (u.connection) {
            // console.log("sending drawing data")
            // console.log(u)
            u.connection.send(data)
          }
        }
      }
    },
    // homeworkDone() {
    //   this.$emit('homeworkdone')
    // },
    showMobileChat() {
      this.$parent.mobileChatShow = !this.$parent.mobileChatShow
    },
    slideSolved(id, val) {
      if(!isNaN(id)) {
        saveSlidesSolved(id, val)
        this.$set(this.slidesSolved, id, val)
      }
    },
    wordsCardsDone() {
      this.$store.commit('toggleLessonMode', 'cards')
      // this.$store.commit('setShowCards', false)
    },
    materialClicked() {
      if(!this.host) return
      this.$bvModal.show('selectmaterialmodal')
    }
  },
  mounted() {
    if (this.homework) {
      this.slidemodechange('homework')
    }

    this.slidesSolved = getSlidesSolved()
  },
  computed: {
    ...mapState({
      bottomBurger:       state => state.call.bottomBurger,
      host:               state => state.call.host,
      selectedUser:       state => state.call.selectedUser,
      newMessage:         state => state.call.newMessage,
      cards:              state => state.call.lessonCards,
      showCards:          state => state.call.showCards,
      slide:              state => state.call.slide,
      slides:             state => state.call.slides,
      slidemode:          state => state.call.slidemode,
      followed:           state => state.call.followed,
      finishedLesson:     state => state.call.finishedLesson,
      lessonModesEnabled: state => state.call.lessonModesEnabled,
      lessonModeFull:     state => state.call.lessonModeFull,
      material:           state => state.call.material,
      socket:             state => state.call.socket,
      room:               state => state.call.room,
      homework:           state => state.call.isHomework,
      isKeyboardOpen:     state => state.isKeyboardOpen,
    }),
    currentSlides() {
      return this.slides[this.slidemode]
    },
    currentSlide() {
      return this.slide[this.slidemode]
    },
    allTasksDone() {
      return this.amountOfSlidesSolved === this.currentSlides.length
    },
    amountOfSlidesSolved() {
      return this.currentSlides.filter(slide => Object.keys(this.slidesSolved).includes((slide.id).toString())).length
    },
  },
  watch: {
    slide() {
      this.$nextTick(() => {
        this.$emit('modechange', 'slide')
      })
    },
  },

}
</script>

<style lang="scss">

//.word-cards-wrapper {
//  position: fixed;
//  left: 72px;
//  top: 0;
//  bottom: 0;
//  width: calc(50vw - 36px);
//  height: 100vh;
//  min-width: 300px;
//  z-index: 5;
//  overflow-y: scroll;
//  border-right: 1px solid $border-color;
//  background: white;
//}
.slide-material {
  background: #F5F6F8;
  border-radius: 6px;
  color: #797D88;
  display: inline-block;
  vertical-align: middle;
  padding: 2px 7px;
  cursor: pointer;
  max-width: 150px;
  .slide-material-text {
    width: calc(100% - 45px);
  }
  .title, .level {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .title {
    font-size: 13px;
  }
  .level {
    font-size: 10px;
  }
}
.canvas-content {
  max-width: 800px;
  margin: 0 auto;
}

@media(max-width: 768px) {

  //.word-cards-wrapper {
  //  left: 0;
  //  width: 100vw;
  //  border-right: none;
  //  height: calc(var(--doc) - 64px);
  //  padding-bottom: 64px;
  //}

  #sidebar .sidebar-group .sidebar-item {
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
  }

  .container-left {
    //margin-left: 70px !important;
    margin-left: 0;
    padding-left: 0 !important;
    padding-right: 18px !important;
  }

  .btn-themed.btn-white {
    font-size: 13px !important;
  }

  .burger_wrapper {
    width: 100%;
    justify-content: space-between !important;
  }

  .navigation-bar_first_col {
    display: none;
  }

  .follow_me {
    display: none;
  }

  #pencet{
    display:flex;
    align-items:center;
    height: 30px;
    flex-direction:column;
    cursor:pointer;
  }

  #pencet span{
    background-color: #000;
    width:2em;
    height:.2em;
    margin:4px 0;
    display:block;
    transition: all .4s ease;
    transform-origin:0 0;
  }

  .Diam span:nth-child(1) {
    transform: rotate(45deg) translate(1px, -1px);
  }

  .Diam span:nth-child(2) {
    Transform: scaleX(0);
  }

  .Diam span:nth-child(3) {
    transform: rotate(-45deg) translate(1px, 0);
  }
}

@media(max-width: 576px) {
  #sidebar {
    width: 0 !important;
    overflow: hidden;

    &.show {
      width: 55px !important;
      overflow: visible;
    }
  }
}


#canvaswrapper {
  position: relative;
  width: 100%;
  user-select: none;

  img {
    max-width: 100%;
  }

  #canvas, video {
    background: transparent;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .blackboard {
    width: 100%;
    height: calc(100vh - 100px);
    border: 1px solid $border-gray;
    box-sizing: border-box;
    padding: 16px;
    border-radius: 16px;
    background: #fff;
  }

  .slide {
    height: 100vh;
    white-space: pre-wrap;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    width: calc(100vw - 72px - 145px);
    &.withoutVideo {
      width: calc(100vw - 72px);
    }
    &.half {
      width: calc(50vw - 36px);
    }
    &.halfer {
      width: calc(50vw - 36px - 72.5px);
      &.withoutVideo {
        width: calc(50vw - 36px);
      }
    }
    &.toRight {
      left: auto;
      right: 145px;
      border-left: 1px solid #E8EFFA;
      &.withoutVideo {
        right: 0;
      }
    }
    .slidehtml {
      white-space: normal;
    }
    .slide-heading {
      height: 60px;
      top: 0;
      position: sticky;
      z-index: 3;
      padding: 0 14px;
      background: #fff;
      display: flex;
      align-items: center;
      h2 {
        font-size: 17px;
      }
    }
    .slide-switcher {
      cursor: pointer;
      font-size: 14px;
      height: 60px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-bottom: 2px solid transparent;
      &.active, &:hover {
        color: $text-themed;
        text-decoration: none;
        border-bottom: 2px solid $text-themed;
      }
      svg {
        width: 18px;
      }
    }
    .slide-nav-btn {
      width: 40px;
      height: 40px;
      background: #F6F7FA;
      border-radius: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .current-slide {
      display: inline-block;
      margin: 0 10px;
    }

    user-select: none;
    background: #fff;
    //border: 1px solid $border-gray;
    box-sizing: border-box;
    //padding: 16px;
    padding-bottom: 70px;
    background: #fff;

    img {
      max-width: 100%;
    }

  }

  .btn-themed.btn-white {
    border-radius: 14px;
    padding: 11px 19px;
  }
}

@media(max-width: 768px) {
  #canvaswrapper {
    .slide {
      width: 100vw;
      z-index: 2;
      //height: calc(100vh - 64px);
      height: calc(var(--doc-height) - 64px);
      &.withoutVideo, &.half, &.halfer {
        width: 100vw;
        bottom: 64px;
      }
      &.half {
        height: calc(var(--doc-height) * 0.65 - 32px);
      }
      &.withoutVideo {
        height: calc(var(--doc-height) - 64px);
      }
      &.halfer {
        height: calc(var(--doc-height) * 0.5 - 32px);
        &.withoutVideo {
          width: 100%;
        }
      }
      &.toRight {
        right: 0;
        top: 0;
      }
      .slide-nav-btn {
        width: 34px;
        height: 34px;
      }
    }
    &.isKeyboardOpen {
      .slide {
        height: calc(var(--doc-height));
        .slide-heading {
          display: none;
        }
        &.withoutVideo, &.half, &.halfer {
          bottom: 0;
        }
        &.half {
          //height: calc(var(--doc-height) * 0.65);
          height: calc(var(--doc-height));
        }
        &.withoutVideo {
          height: calc(var(--doc-height));
        }
        &.halfer {
          //height: calc(var(--doc-height) * 0.5);
          height: calc(var(--doc-height));
        }
      }
    }
  }


}

.alert-span {
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 100%;
  background: $theme-red;
  position: relative;
  top: -5px;
}

video {
  background: transparent;
  -webkit-filter: contrast(130%);
}


</style>






