<template>
  <div class="preview-slides">
    <div class="slide" v-if="slides.length > 0">
      <h2 class="slide-header border-bottom-dashed"><span v-if="!material.light_course_lesson_id">{{ slides[slide].title }}, </span>{{ (slide + 1) }} / {{ slides.length }}</h2>
      <TaskComponentsParser v-if="slides[slide].components"
                            class="slidehtml"
                            :slide="slide"
                            :slidecomponents="slides[slide].components"
                            :host="host"
                            source="preview"
                            :key="'parser-for-slide-'+slide+'-'+key+'-'+slidemode"/>
    </div>
  </div>
</template>

<script>
import TaskComponentsParser from "@/views/parts/class/preview/TaskComponentsParser"

export default {
  components: {
    TaskComponentsParser
  },
  data() {
    return {
      key: Math.random().toString().substr(2, 5)
    }
  },
  props: {
    slides: { type: Array},
    homework: { type: Boolean },
    host: { type: Boolean, default: false },
    slide: {},
    slidemode: {},
    material: {}
  },
}
</script>

<style scoped lang="scss">

.preview-slides {
  position: relative;
  width: 100%;
  user-select: none;
  img {
    max-width: 100%;
  }
  .slide {
    white-space: pre-wrap;
    .slidehtml {
      white-space: normal;
    }
    user-select: none;
    background: #fff;
    padding: 0;
    padding-top: 30px;
    padding-bottom: 20px;
    border-radius: 16px;
    background: #fff;
    img {
      max-width: 100%;
    }
    #cursor {
      width: 10px;
      height: 10px;
      margin-left: -5px;
      margin-top: -5px;
      border-radius: 100%;
      background: skyblue;
      display: inline-block;
      position: absolute;
    }
  }
  .btn-themed.btn-white {
    border-radius: 14px;
    padding: 11px 19px;
  }
}

</style>






