<template>
  <div>
    <b-modal id="selectmaterialmodal"
             size="md"
             @show="onShow"
             @hide="onHide"
             centered
             hide-footer
             hide-header
             modal-class="modal-right-pane">
      <b-row>
        <b-col>
          <a @click="back" v-if="selectedcourse" class="mr-3 btn-themed btn-white back-btn">
            <LeftSvg/>
          </a>
          <h2 class="mb-0 d-inline-block align-middle">{{ selectedcourse ? selectedcourse.title : $t('select_course') }}</h2>
        </b-col>
        <b-col cols="auto">
          <a class="pointer d-inline-block position-relative modal-close" @click="$bvModal.hide('selectmaterialmodal')">
            <CloseSvg/>
          </a>
        </b-col>
      </b-row>

      <div class="mt-4">
        <div v-if="!loaded">
          <Loader class="mt-5"/>
        </div>
        <div v-if="!selectedcourse">
          <template v-for="(carr, ctype) in courses">
            <template v-if="ctype === 'teacher'">
              <template v-for="(c,cind) in carr">
                <div v-for="cc in c" :key="`crs-${ctype}-${cind}-${cc.id}`">
                  <CourseIcon :course="cc"
                              :ctype="ctype"
                              v-if="cc.materials && cc.materials.length > 0"
                              @click.native="courseSelected(cc)"/>
                </div>
              </template>
            </template>
            <template v-else>
              <template v-for="(c,cind) in carr">
                <CourseIcon :course="c"
                            :ctype="ctype"
                            @click.native="courseSelected(c)"
                            v-if="c.materials && c.materials.length > 0"
                            :key="'course-'+ctype+'-'+cind"/>
              </template>
            </template>
          </template>
        </div>
        <div v-if="selectedcourse">
          <div v-for="(level) in levels" :key="'level-'+level.id">
            <template  v-if="materials && materials[level.id]">
              <AccessoryRow :title="level.code+'. '+level.title" v-b-toggle="'collapse-'+level.id"/>
              <b-collapse :id="'collapse-'+level.id">
                <div class="subcategory-wrapper">
                  <div v-for="(material, mind) in materials[level.id]"
                       class="material-to-select"
                       :class="{ selected: materialPassed && material.id == materialPassed.id }"
                       :key="'material-'+mind">
                    <div class="subcategory" @click="materialSelected(material)">
                      <b-row class="align-items-center">
                        <b-col cols="auto">
                          <LetterIcon :obj="material" :title="material.title" class="student-icon sm"/>
                        </b-col>
                        <b-col>{{ material.title }}
                          <a class="btn-themed view-btn" @click.stop="preview(material)">{{ $t('preview') }}</a>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </template>
          </div>
        </div>
      </div>
      <PresentationPreviewModal v-if="showmodal"
                                :material="previewmaterial"
                                :homework="false"/>
    </b-modal>
  </div>
</template>

<script>
import { CoursesService } from '@/services/api.service'
import CourseIcon from '@/views/teasers/CourseIcon'
import CloseSvg from '@/assets/svg/close.svg'
import LeftSvg from '@/assets/svg/left.svg'
import AccessoryRow from '@/views/teasers/AccessoryRow'
import Loader from "@/views/parts/general/Loader"
import LetterIcon from '@/views/parts/general/LetterIcon'
import PresentationPreviewModal from '@/views/parts/class/preview/PresentationPreviewModal'

export default {
  components: {
    CloseSvg,
    AccessoryRow,
    LeftSvg,
    CourseIcon,
    Loader,
    PresentationPreviewModal,
    LetterIcon
  },
  props: {
    coursePassed: {},
    materialPassed: {}
  },
  data() {
    return {
      loaded: false,
      courses: {},
      levels: [],
      selectedcourse: null,
      materials: [],
      showmodal: false,
      previewmaterial: null
    }
  },
  methods: {
    back() {
      this.selectedcourse = null
      this.materials = []
    },
    courseSelected(course) {
      if(!course) return
      this.selectedcourse = course
      this.materials = course.materialsKeyed
    },
    materialSelected(m) {
      this.$emit('selected',m)
      this.$bvModal.hide('selectmaterialmodal')
    },
    preview(material) {
      this.previewmaterial = material
      this.showmodal = true
      setTimeout(() => {
        this.$bvModal.show('presentationpreviewmodal')
      },50)
    },
    onShow() {
      this.selectedcourse = null
      CoursesService.get().then(res => {
        this.courses = res.data.data.courses
        this.levels = res.data.data.levels
        if(this.coursePassed) {
          let allCourses = this.courses.custom.concat(this.courses.standard, this.courses.school, this.courses.teacher)
          let course = allCourses.find(c => c.id == this.coursePassed.id)
          this.courseSelected(course)
          if(this.materialPassed) {
            // console.log(this.materialPassed)
            // this.$nextTick(() => {
            setTimeout(() => {
              console.log('collapse-'+this.materialPassed.level_id)
              this.$root.$emit('bv::toggle::collapse', 'collapse-'+this.materialPassed.level_id)
            }, 20)

            // })
          }
          // console.log(course)

        }
        this.loaded = true
      })
    },
    onHide() {
      this.courses = {}
      this.selectedcourse = null
      this.showmodal = false
      this.loaded = false
    }
  },
  mounted() {

  },

}
</script>

<style scoped lang="scss">
.material-to-select {
  &.selected {
    background: $text-themed-light2;
    border: 2px solid $text-themed-light;
    border-radius: 16px;
  }
}
</style>






