<template>
    <div>
        <div class="modal_page">
            <div class="video_wrapper d-flex flex-wrap align-items-center">
                <div class="myWindow mb-1 col-12"
                     :class="{'col-md-7': numOfUsers == 1, 'col-md-6': numOfUsers == 2, 'col-md-4': numOfUsers > 2 }">
                    <CallParticipantTeaser id="localVideo1"
                                           :user="$store.state.user"
                                           :modal="true"
                                           :numOfUsers="numOfUsers"
                                           :uid="$store.state.user.id"
                                           :udata="udata"/>
                </div>
                <div :class="{ 'col-md-6': numOfUsers == 2, 'col-md-4': numOfUsers > 2 }"
                     v-for="(ud, peerUserId) in usersdata"
                     class="mb-1 col-12"
                     :key="'remote-'+peerUserId">
                    <CallParticipantTeaser
                            :uid="ud.dbUserId"
                            :modal="true"
                            :numOfUsers="numOfUsers"
                            :user="students[ud.dbUserId]"
                            :udata="ud"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CallParticipantTeaser from "../../teasers/CallParticipantTeaser";
    import EventBus from '@/services/event-bus'

    export default {
        name: "fullsizeModal",
        components: { CallParticipantTeaser },
        data() {
            return {
                udata: {},
                usersdata: [],
                students: {}
            }
        },
        mounted() {
            EventBus.$on('videoFullData', data => {
                this.udata = {
                    video: !data.videoMuted,
                    remotestream: data.remotestream,
                    audio: !data.audioMuted,
                    host: data.host
                },
                this.usersdata = data.usersdata,
                this.students = data.students
            })
        },
        computed: {
            numOfUsers() {
                return (Object.keys(this.usersdata).length + 1)
            },
        },
    }
</script>

<style lang="scss" scoped>
    .modal_page {
        z-index: 100;
        right: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 0 50px;
    }

    .video_wrapper {
        width: 100%;
        height: 100%;
        padding: 85px 0;
    }

    .myWindow {
        margin: 0 auto;
    }

    @media(max-width: 1024px) {
        .video_wrapper {
            display: flex;
            align-items: center;
            padding: 85px 0;
        }
    }

</style>
