<template>
  <div id="studentresponsesmodal"
       ref="studentresponsesmodal"
       class="custom-modal"
       :class="{ shown: shown && loaded }"
       @dragstart.stop="dragStart"
       @drag.prevent.stop="drag"
       @dragend.stop="dragEnd"
       :style="{ transform: 'translate3d(' + currentX + 'px, ' + currentY + 'px, 0)' }"
       :draggable="true"
       @hide="amountOfQuestions = 0"
       @show="loadResponses">
    <!--    :style="{ transform: 'translate3d(' + currentX + 'px, ' + currentY + 'px, 0)' }"-->
    <!--    :style="{ left: currentX+'px', top: currentY+'px' }"-->
    <div v-if="loaded">
      <div class="d-flex justify-content-between">
        <h2>{{ $t('student_responses') }}</h2>
        <a class="pointer position-relative modal-close" @click="close">
          <CloseSvg/>
        </a>
      </div>
      <div class="stud-table-wrapper">
        <table class="table stud-r-table">
          <thead>
          <tr>
            <th v-for="(r, rind) in responses" :key="`response-${rind}`">
              <b-row class="align-items-center">
                <b-col cols="auto">
                  <LetterIcon :obj="r.student" :title="r.student.name" class="student-icon sm"/>
                </b-col>
                <b-col>
                  {{ r.student.name }}
                </b-col>
              </b-row>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="i in amountOfQuestions" :key="`row-${i}`">
            <td v-for="(r, rind) in responses" :key="`response-${i}-${rind}`">
              <div class="point-counter">{{ i }}.</div>
              <component :is="icons[parseResponse(r.responses, i)]"/>
            </td>
          </tr>
          </tbody>
        </table>
        <!--                <b-row class="flex-nowrap">-->
        <!--                  <b-col v-for="(r, rind) in responses" :key="`response-${rind}`">-->
        <!--                    <b-row class="align-items-center">-->
        <!--                      <b-col cols="auto">-->
        <!--                        <LetterIcon :obj="r.student" :title="r.student.name" class="student-icon sm"/>-->
        <!--                      </b-col>-->
        <!--                      <b-col>-->
        <!--                        {{ r.student.name }}-->
        <!--                      </b-col>-->
        <!--                    </b-row>-->
        <!--                  </b-col>-->
        <!--                </b-row>-->
        <!--                <div class="mt-3 mb-3 responses-scroll">-->
        <!--                  <b-row class="flex-nowrap" v-for="i in amountOfQuestions" :key="`row-${i}`">-->
        <!--                    <b-col v-for="(r, rind) in responses" :key="`response-${i}-${rind}`" class="nowrap">-->
        <!--                      <div class="point-counter">{{ i }}.</div>-->
        <!--                      <component :is="icons[parseResponse(r.responses, i)]"/>-->
        <!--                    </b-col>-->
        <!--                  </b-row>-->
        <!--                </div>-->
      </div>
      <hr/>
      <div class="text-right">
        <b-button class="btn-themed btn-white mr-3"
                  @click="loadResponses(false)">
          {{ $t('refresh') }}
        </b-button>
        <b-button class="btn-themed"
                  :disabled="!loaded"
                  v-if="!responsesShown"
                  @click="showAnswersToStudents">
          {{ $t('show_answers2') }}
        </b-button>
        <b-button class="btn-themed btn-white"
                  :disabled="!loaded"
                  v-if="responsesShown"
                  @click="hideAnswersToStudents">
          {{ $t('hide_answers') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

import CloseSvg from '@/assets/svg/close.svg'
import RightSvg from '@/assets/svg/correct.svg'
import WrongSvg from '@/assets/svg/wrong.svg'
import NotRespondedSvg from '@/assets/svg/dash.svg'
import {mapState} from "vuex"
import {LessonsService} from "../../../services/api.service"
import LetterIcon from '@/views/parts/general/LetterIcon'
import EventBus from '@/services/event-bus'

export default {
  name: "StudentsResponsesModalCustom",
  components: {
    CloseSvg,
    LetterIcon,
    RightSvg,
    WrongSvg,
    NotRespondedSvg
  },
  props: {
    students: {}
  },
  computed: {
    ...mapState({
      lessonId: state => state.call.lessonId,
      blockId: state  => state.call.blockId,
    }),
    responsesShown() {
      return this.$store.state.call.blocksShowingResponses.includes(this.blockId)
    },
  },
  data() {
    return {
      loaded: false,
      responses: null,
      amountOfQuestions: 0,
      // left: 0,
      // top: 0,
      currentX: 0,
      currentY: 0,
      initialX: 0,
      initialY: 0,
      xOffset: 0,
      yOffset: 0,
      dragged: false,
      icons: {
        'w': WrongSvg,
        'r': RightSvg,
        '-': NotRespondedSvg
      },
      shown: false
    }
  },
  methods: {
    showAnswersToStudents() {
      this.$store.commit('setBlockForResponses', this.blockId)
      this.$emit('showBlockResponses')
    },
    hideAnswersToStudents() {
      this.$store.commit('removeBlockForResponses', this.blockId)
      this.$emit('showBlockResponses')
    },
    async loadResponses(skipLoaded = true) {
      if(skipLoaded) this.loaded = false
      let res = await LessonsService.getResponsesForBlockForAllStudents(this.lessonId, this.blockId)
      this.responses = res.data.data.storage
      this.loaded = true
      this.amountOfQuestions = res.data.data.questions
    },
    parseResponse(response, i) {
      i = i - 1
      if(!response.wnr || !response.wnr[i]) return '-'
      return response.wnr[i]
    },
    close() {
      this.shown = false
    },
    dragStart(event) {
      var img = document.createElement("img");
      img.src = "/images/empty.png";
      event.dataTransfer.setDragImage(img, 0, 0);
      if (event.type === 'touchstart') {
        this.initialX = event.touches[0].clientX - this.xOffset
        this.initialY = event.touches[0].clientY - this.yOffset
      } else {
        this.initialX = event.clientX - this.xOffset
        this.initialY = event.clientY - this.yOffset
      }
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      this.dragged = true
    },
    dragEnd() {
      this.initialX = this.currentX
      this.initialY = this.currentY
      this.dragged = false
    },
    drag(event) {
      if(!this.dragged) return
      let x = 0
      let y = 0
      if (event.type === 'touchmove') {
        x = event.touches[0].clientX - this.initialX
        y = event.touches[0].clientY - this.initialY
      } else {
        x = event.clientX - this.initialX
        y = event.clientY - this.initialY
      }
      if(x > 0) this.currentX = x
      if(y > 0) this.currentY = y

      this.xOffset = this.currentX
      this.yOffset = this.currentY
    }
  },
  mounted() {
    EventBus.$on('studentresponsesmodal', () => {
      if(!this.shown) {
        if(this.currentX === 0) {
          if(window.innerWidth >= 400) {
            this.currentX = ((window.innerWidth / 2) - 200)
            this.xOffset = this.currentX
            this.currentY = 100
            this.yOffset = this.currentY
          }
        }
        this.shown = true
      }
      this.loadResponses()
    })
  }

}
</script>

<style scoped lang="scss">
#studentresponsesmodal {
  position: fixed;
  left: 0;
  top: 0;
  width: 400px;
  touch-action: none;
  user-select: none;
  background: #fff;
  border-radius: 24px;
  padding: 20px;
  z-index: 30;
  display: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  &.shown {
    display: block;
  }
  .responses-scroll {
    overflow-y: scroll;
    //overflow-x: hidden;
    padding-right: 15px;
    width: 100%;
    max-height: calc(100vh - 320px);
  }
  .stud-r-table {
    td {
      white-space: nowrap;
      padding: 0 5px 0 0;
      border-top: none;
    }
    thead th {
      position: sticky;
      top: 0;
      padding: 0 5px 10px 0;
      background: #fff;
      border-bottom: none;
      border-top: none;
    }
    //tbody {
    //  max-height: calc(100vh - 570px);
    //  overflow-y: scroll;
    //}
    //  width: 100%;
    //  overflow-x: scroll;
  }
  .stud-table-wrapper {
    overflow-x: scroll;
    max-height: calc(100vh - 370px);
    width: 100%;
  }
  .nowrap {
    white-space: nowrap;
    min-width: 60px;
  }
}
.point-counter {
  width: 25px;
  display: inline-block;
}
@media(max-width: 400px) {
  #studentresponsesmodal {
    width: calc(100% - 40px);
    left: 20px;
    top: 20px;
  }
}
</style>
