<template>
    <div class="full_size">
        <fullsizeModal />
    </div>
</template>

<script>
    import fullsizeModal from "./fullsizeModal";
    export default {
        name: "fullsizeCover",
        components: { fullsizeModal },
    }
</script>

<style lang="scss" scoped>
    .full_size {
        width: calc(100% - 70px);
        right: 0;
        height: 100%;
        background: rgba(71, 75, 81, 0.4);
        backdrop-filter: blur(12px);
        z-index: 100;
        position: fixed;
    }
</style>
