const KEY_ADDITION = "slide-"
import router from '@/router/index'

export const getSlidesSolved = () => {
    let d = window.localStorage.getItem(getKey())
    if(d) return JSON.parse(d)
    return {}
}

export const saveSlidesSolved = (id, results) => {
    let obj = getSlidesSolved()
    obj.id = results
    let res = JSON.stringify(obj)
    window.localStorage.setItem(getKey(), res)
}

export const deleteSlidesSolved = () => {
    window.localStorage.removeItem(getKey())
}

const getLessonId = () => {
    const route = router.app.$route
    if(route.name === 'Lesson') {
        return route.params.id
    }
    return null
}

const getKey = () => {
    const lesson_id = getLessonId()
    if(lesson_id) {
        return KEY_ADDITION+lesson_id.toString()
    }
    return KEY_ADDITION
}



export default {
    getSlidesSolved,
    saveSlidesSolved,
}
