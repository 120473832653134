<template>
  <b-modal id="select-source-modal"
           size="lg"
           hide-footer
           @hide="resolveFunction(source)"
           modal-class="rounded">
    <h3 class="text-center">{{ $t('choose_screen_to_share') }}</h3>
    <b-row>
      <b-col v-for="source in sources" :key="`s-${source.id}`" cols="6" sm="4" class="mb-3">
        <div @click="selectedSource(source)" class="text-center pointer px-2">
          <img :src="source.thumbnail.toDataURL()" alt="" class="img-source"/>
          <div class="mt-2 small">
            {{ source.name }}
          </div>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: "SourceSelectModal",
  data() {
    return {
      sources: [],
      source: null,
      resolveFunction: null,
    }
  },
  methods: {
    chooseSource() {
      return new Promise((resolve) => {
        try {
          this.resolveFunction = resolve
          const electron = window.require('electron')
          electron.ipcRenderer.invoke('get-sources').then(res => {
            this.sources = res
            this.$bvModal.show('select-source-modal')
          })
        } catch (e) {
          resolve(null)
        }
      })
    },
    selectedSource(source) {
      this.source = source
      this.$bvModal.hide('select-source-modal')
    }
  }
}
</script>

<style scoped>
  .img-source {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 5px;
  }
</style>
