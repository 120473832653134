<template>
  <vue-resizable :left="isMobile ? 0 : 72"
                 :disableAttributes="[]"
                 :minHeight="300"
                 dragSelector=".drag-part">
    <div id="lessonaudiomodal" class="spacecard" ref="lessonaudiomodal">
      <div class="drag-part">
        <b-row class="align-items-center">
          <b-col>
            <h2 class="mb-0">{{ $t('audio') }}</h2>
          </b-col>
          <b-col cols="auto">
            <a class="pointer position-relative modal-close call_room_btn" @click="$parent.audioShow = false">
              <CloseSvg/>
            </a>
          </b-col>
        </b-row>
      </div>
      <hr/>
      <div v-if="material" class="audio-content">
        <div v-for="(a, aind) in material.audio_files"
             class="mt-2"
             :key="`audio-file-${aind}`">
          <div class="mb-1 mt-3">{{ a.name }}</div>
          <audio v-if="a.path"
                 controls
                 :ref="`audio${aind}`"
                 @play="startedAudio(a, aind)"
                 @pause="stoppedAudio">
            <source :src="a.path" type="audio/mpeg">
          </audio>
        </div>
        <CheckboxGroup class="mt-3"
                       v-if="hasSocket"
                       @input="translateToAllChanged"
                       :label="$t('translate_to_all')"
                       v-model="translateToAll"
                       fid="translateToAll"/>
      </div>
    </div>
  </vue-resizable>
</template>

<script>

import CloseSvg from '@/assets/svg/close_gray.svg'
import VueResizable from 'vue-resizable'
import {mapState} from "vuex"
import CheckboxGroup from "../general/form/CheckboxGroup"
import {isSafari} from "mobile-device-detect"

export default {
  name: "LessonAudioModalCustom",
  components: {
    CheckboxGroup,
    CloseSvg,
    VueResizable
  },
  props: {
    socket: {},
  },
  computed: {
    ...mapState({
      room:     state => state.call.room,
      material: state => state.call.material,
      isMobile: state => state.isMobile,
      host:     state => state.call.host,
    }),
    responsesShown() {
      return this.$store.state.call.blocksShowingResponses.includes(this.blockId)
    },
    hasSocket() {
      return this.socket
    },
    windowW() {
      return window.innerWidth
    },
    windowH() {
      return window.innerHeight
    }
  },
  data() {
    return {
      audio: null,
      currentAudioIndex: null,
      currentAudio: null,
      translateToAll: true
    }
  },
  methods: {
    startedAudio(audio, index) {
      let currentTime = this.$refs['audio'+index][0].currentTime
      this.currentAudio = audio
      this.currentAudioIndex = index
      if(this.translateToAll && this.hasSocket && this.host) {
        this.socket.emit('customevent', 'lessonAudioPlayed', this.room, audio, currentTime)
      }
    },
    stoppedAudio() {
      this.currentAudio = null
      this.currentAudioIndex = null
      if(this.translateToAll && this.hasSocket && this.host) {
        this.socket.emit('customevent', 'lessonAudioStop', this.room)
      }
    },
    translateToAllChanged() {
      if(this.currentAudio !== null) { // means playing
        if(this.translateToAll) {
          this.startedAudio(this.currentAudio, this.currentAudioIndex)
        }
        if(!this.translateToAll && this.hasSocket && this.host) {
          this.socket.emit('customevent', 'lessonAudioStop', this.room)
        }
      }
    },
  },
  watch: {
    socket: {
      handler(val) {
        if(val) {
          this.socket.on('lessonAudioPlayed', (audio, currentTime) => {
            if(this.audio && !this.audio.paused) this.audio.pause()
            if(isSafari) {
              this.$emit('showModal')
            } else {
              this.audio = new Audio(audio.path)
              this.audio.currentTime = currentTime
              this.audio.play()
            }
          })
          this.socket.on('lessonAudioStop', () => {
            if(this.audio) this.audio.pause()
            this.audio = null
          })
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
#lessonaudiomodal {
  width: 600px;
}
.point-counter {
  width: 25px;
  display: inline-block;
}
.audio-content {
  max-height: 70vh;
  overflow-y: scroll;
}
@media(max-width: 768px) {
  #lessonaudiomodal {
    width: 100vw;
  }
  .spacecard {
    border-radius: 0;
    border: none;
    height: calc(100vh - 64px);
  }
  .audio-content {
    max-height: calc(100vh - 140px);
  }
}
</style>
