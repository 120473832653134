<template>
  <div class="lesson-presentation-part"
       :class="{
           shown: lessonModesEnabled.includes('presentation'),
           half: lessonModesEnabled.length === 1 && !lessonModeFull,
           halfer: lessonModesEnabled.length === 2,
           toRight: lessonModesEnabled.length === 2 && lessonModesEnabled.indexOf('presentation') == 1,
           withoutVideo: view === 'selfstudy' || homework,
           host: host,
           inZoomMode: zoomMode
         }"
       id="presentationcanvas"
       ref="presentationcanvas"
       @mousemove="mousemove"
       @scroll="onscroll"
       v-if="material && material.presentation_files && material.presentation_files.length">
    <div class="lesson-mode-header">
      <button class="call_room_btn"
              @click="$store.commit('expandLessonMode', 'presentation')">
        <ExpandSvg v-if="!lessonModeFull"/><CollapseSvg v-if="lessonModeFull"/>
      </button>
      <button class="call_room_btn ml-2"
              :class="{ active: zoomMode }"
              @click="zoomMode = !zoomMode; zoomDisabled = false">
        <MagnifierSvg fill="#9499A6"/>
      </button>
      <button class="call_room_btn ml-2 d-md-inline d-none"
              v-if="!lessonModeFull && lessonModesEnabled.length > 1"
              @click="$store.commit('switchSideLessonMode', 'presentation')">
        <SwitchSvg/>
      </button>
      <button class="call_room_btn ml-2"
              @click="$store.commit('toggleLessonMode', 'presentation')">
        <CloseSvg/>
      </button>
    </div>
    <div class="slide-part">
      <div v-for="(m, mind) in material.presentation_files" :key="`pr-${mind}`">
        <div v-for="(p, pind) in m.path"
             :key="`pr-${mind}-${pind}`"
             :ref="`pdf-${mind}-${pind}`"
             class="pdf-container position-relative"
        >
          <hr class="my-3" v-if="mind > 0"/>
          <!--          <div class="w-100" v-if="zoomMode && zoomOnHoverLibraryEnabled">-->
          <!--            <zoom-on-hover :img-normal="p"-->
          <!--                           @click.native="zoomFreeze"-->
          <!--                           :disabled="zoomDisabled"-->
          <!--                           :scale="2">-->
          <!--            </zoom-on-hover>-->
          <!--          </div>-->
          <!--          <image-magnifier :src="p" -->
          <!--                           v-if="zoomMode && !zoomOnHoverLibraryEnabled" -->
          <!--                           :src-large="p"/>-->
          <img :src="p"
               :ref="`img-${mind}-${pind}`"
               alt=""
               class="img presentation-img"
               @mouseover="enterImage(mind, pind, ...arguments)"
               @touchstart="enterImage(mind, pind, ...arguments)"
               @mouseout="leaveImage(mind, pind, ...arguments)"
               @touchend="leaveImage(mind, pind, ...arguments)"
               @mousemove="moveImage(mind, pind, ...arguments)"
               @touchmove="moveImage(mind, pind, ...arguments)"
               onContextMenu="return false;"/>
          <div class="zoom"
               @click="zoomFreeze"
               v-if="zoomMode && currentZoomIndex == `${mind}-${pind}`"
               :ref="`zoom-${mind}-${pind}`"
               :style="`left: ${zoomLeft}px; top: ${zoomTop}px`">
            <img class="zoom-image"
                 :ref="`zoom-image-${mind}-${pind}`"
                 :style="`left: ${zoomImgLeft}px; top: ${zoomImgTop}px; width: ${imgW}px`"
                 :src="p"
                 alt="">
          </div>
        </div>
      </div>
      <div v-show="cursorShow"
           ref="cursorp"
           id="cursorp"
           :style="{
                left: cursorLeft+'px',
                top: cursorTop+'px',
                'margin-left': (-currentSize/2)+'px',
                'margin-top': (-currentSize/2)+'px',
                width: currentSize+'px',
                height: currentSize+'px'
          }">
      </div>

    </div>
  </div>
</template>

<script>

import CloseSvg from "@/assets/svg/close_gray.svg"
import SwitchSvg from "@/assets/svg/switch-sides.svg"
import MagnifierSvg from "@/assets/svg/search.svg"
import ExpandSvg from "@/assets/svg/expand.svg"
import CollapseSvg from "@/assets/svg/collapse.svg"
import EventBus from '@/services/event-bus'
import {mapState} from "vuex"
// import ImageMagnifier from "./ImageMagnifier"

export default {
  name: "LessonPresentationPart",
  components: {
    // ImageMagnifier,
    CloseSvg,
    SwitchSvg,
    MagnifierSvg,
    ExpandSvg,
    CollapseSvg
  },
  props: {
    userId: {},
    usersdata: {},
    presentationViewMode: {},
    view: { type: String, default: 'lesson' },
  },
  computed: {
    ...mapState({
      host: state => state.call.host,
      linkchatUrl: state => state.call.linkchatUrl,
      homework: state => state.call.isHomework,
      material: state => state.call.material,
      lessonModesEnabled: state => state.call.lessonModesEnabled,
      lessonModeFull: state => state.call.lessonModeFull,
    }),
    // showPresentation() {
    //   return this.lessonModesEnabled.includes('presentation')
    // }
  },
  data() {
    return {
      // width: 0,
      canvas: null,
      cursorShow: false,
      cursorLeft: 0,
      cursorTop: 0,
      currentSize: 10,
      padding: 20,
      canvasHeader: 85,
      zoomMode: false,
      zoomDisabled: false,
      zoomImageSrc: null,
      zoomTop: null,
      zoomLeft: null,
      zoomImgTop: null,
      zoomImgLeft: null,
      imgW: 0,
      zoomLevel: 2,
      currentZoomIndex: null
      // hoveredPage: null,
      // zoomOnHoverLibraryEnabled: false,
    }
  },
  methods: {
    resizeListener() {
      if(this.$refs.presentationcanvas) {
        this.imgW = this.$refs.presentationcanvas.clientWidth * this.zoomLevel
      }
    },
    mousemove(e) {
      if (!this.host || this.zoomMode || !this.canvas) return
      const rect = this.canvas.getBoundingClientRect()
      const currentPosition = {
        x: e.clientX - this.padding - rect.left,
        y: e.clientY - this.canvasHeader - rect.top
      }
      let w = rect.width - (this.padding * 2)
      let h = this.canvas.scrollHeight - (this.canvasHeader + this.padding)
      this.catchCursorMovement((currentPosition.x / w) * 100, (currentPosition.y / h) * 100, (this.canvas.scrollTop / this.canvas.scrollHeight) * 100)
    },
    scroll() {
      this.catchCursorMovement(null, null, (this.canvas.scrollTop / this.canvas.scrollHeight) * 100, true)
    },
    catchCursorMovement(x, y, scroll, onlyScroll = false) {
      let data = {
        type: 'presentationCursor',
        x: x,
        y: y,
        scroll: scroll,
        onlyScroll: onlyScroll
      }
      this.drawCursor(x, y, scroll, onlyScroll)
      this.sendDataThroughPeerConnections(data)
    },
    drawCursor(x, y, scroll, onlyScroll = false) {
      if (!x && !y && !onlyScroll) {
        this.cursorShow = false
        return
      }
      if(!this.canvas) this.canvas = document.getElementById('presentationcanvas')
      let scrollTop = (scroll * this.canvas.scrollHeight) / 100;
      let scrollHeight = this.canvas.scrollHeight
      if(!onlyScroll) {
        this.cursorLeft = (x * (this.canvas.offsetWidth - (this.padding * 2))) / 100 + this.padding
        this.cursorTop = Math.min((((y * (scrollHeight - (this.canvasHeader + this.padding))) / 100 + this.canvasHeader) + scrollTop), scrollHeight);
      }
      this.cursorShow = true
      if(!this.host) {
        scrollTop = Math.max(0, scrollTop)
        scrollTop = Math.min(scrollTop, (scrollHeight - this.canvas.offsetHeight))
        this.canvas.scrollTop = scrollTop
      }
    },
    sendDataThroughPeerConnections(data) {
      for (let prop in this.usersdata) {
        if (Object.prototype.hasOwnProperty.call(this.usersdata, prop)) {
          if (prop == this.userId) return
          let u = this.usersdata[prop]
          if (u.connection) u.connection.send(data)
        }
      }
    },
    onscroll() {
      if(this.host) {
        this.scroll()
      }
    },
    zoomFreeze() {
      // console.log('freeze')
      this.zoomDisabled = !this.zoomDisabled
    },
    enterImage(mind, pind, e) {
      if(!this.zoomMode) return false
      this.currentZoomIndex = `${mind}-${pind}`
      this.resizeListener()
      this.$nextTick(() => {
        const zoom = this.$refs[`zoom-${mind}-${pind}`] ? this.$refs[`zoom-${mind}-${pind}`][0] : null
        if(!zoom) return
        const container = this.$refs[`pdf-${mind}-${pind}`][0]
        zoom.classList.add('show', 'loading')
        let posX, posY, touch = false

        if (e.touches) {
          posX = e.touches[0].layerX || e.touches[0].clientX
          posY = e.touches[0].pageY - container.getBoundingClientRect().top
          touch = true
        } else {
          posX = e.layerX || e.clientX
          posY = e.offsetY
        }

        const offset = touch ? 1.25 : 2
        this.zoomTop = posY - zoom.offsetHeight / offset
        this.zoomLeft = posX - zoom.offsetWidth / 2

        setTimeout(() => {
          zoom.classList.remove('loading')
        }, 500)
      })
    },
    leaveImage(mind, pind) {
      if(!this.zoomMode) return false
      const zoom = this.$refs[`zoom-${mind}-${pind}`][0]
      this.currentZoomIndex = null
      if(zoom) {
        zoom.style.transform = null
        zoom.classList.remove('show')
      }
    },
    moveImage(mind, pind, e) {
      if(!this.zoomMode) return false
      e.preventDefault()

      const zoom = this.$refs[`zoom-${mind}-${pind}`][0]
      const zoomImage = this.$refs[`zoom-image-${mind}-${pind}`][0]
      const container = this.$refs[`pdf-${mind}-${pind}`][0]
      const img = this.$refs[`img-${mind}-${pind}`][0]
      let posX, posY, touch = false
      if(!zoom || !zoomImage || !container || !img) return false

      if (e.touches) {
        posX = e.touches[0].layerX || e.touches[0].clientX
        posY = e.touches[0].pageY - container.getBoundingClientRect().top
        touch = true
      } else {
        posX = e.layerX || e.clientX
        posY = e.offsetY
      }

      const offset = touch ? 1.25 : 2 // move the zoom little up on mobile (because of your fat fingers :<)
      this.zoomTop = posY - zoom.offsetHeight / offset
      this.zoomLeft = posX - zoom.offsetWidth / 2

      let percX = (posX - img.offsetLeft) / img.offsetWidth
      let percY = (posY - img.offsetTop) / img.offsetHeight

      this.zoomImgLeft = -percX * zoomImage.offsetWidth + (zoom.offsetWidth / 2)
      this.zoomImgTop = -percY * zoomImage.offsetHeight + (zoom.offsetHeight / 1)
    }
  },
  mounted() {
    this.canvas = document.getElementById('presentationcanvas')
    setTimeout(() => {
      this.canvas = document.getElementById('presentationcanvas')
    }, 500)
    window.addEventListener('resize', this.resizeListener)
    if(!this.host) {
      EventBus.$on('presentationCursor', (x, y, scroll, onlyScroll) => {
        this.drawCursor(x, y, scroll, onlyScroll)
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener)
  }

}
</script>

<style scoped lang="scss">

.lesson-presentation-part {
  position: fixed;
  left: 72px;
  top: 0;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #fff;
  z-index: 19;
  width: calc(100vw - 72px - 145px);
  &.withoutVideo {
    width: calc(100vw - 72px);
  }
  &.half {
    width: calc(50vw - 36px);
  }
  &.halfer {
    width: calc(50vw - 36px - 72.5px);
    &.withoutVideo {
      width: calc(50vw - 36px);
    }
  }
  &.toRight {
    left: auto;
    right: 145px;
    border-left: 1px solid #E8EFFA;
    &.withoutVideo {
      right: 0;
    }
  }
  &.host {
    .slide-part {
      cursor: none;
    }
  }
  //border: 1px solid $border-gray;
  display: none;
  &.shown {
    display: block;
  }
  &.right {
    left: auto;
    right: 0;
  }
  img {
    //pointer-events: none;
    user-select: none;
  }
  .header {
    position: sticky;
    top: 0;
    z-index: 20;
  }
  #cursorp {
    width: 10px;
    height: 10px;
    margin-left: -5px;
    margin-top: -5px;
    z-index: 3;
    border-radius: 100%;
    background: $text-themed;
    display: inline-block;
    position: absolute;
  }
}
//.magnifying-glass {
//  position: absolute;
//  z-index: 10;
//  width: $magnifier-width;
//  height: $magnifier-height;
//  display: inline-block;
//  transform: translate(
//              (-1 * $magnifier-width/2),
//              (-1 * $magnifier-width/2)
//  );
//}
.lesson-mode-header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 6;
}

.presentation-img {
  z-index: 1;
}
.zoom {
  width: 30rem;
  height: 15rem;
  background: #fff;
  border-radius: 10px;
  position: absolute;
  border: 2px solid $text-themed;
  pointer-events: none;
  transition: transform .25s ease, opacity 0s linear .25s, background .25s ease;
  opacity: 0;
  transform: scale(0);
  transform-origin: 50% 50%;
  overflow: hidden;


  &:before {
    content: '';
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 5%;
    //display: true;
    //cursor: true;
  }

  &.show {
    transform: scale(1);
    opacity: 1;
    transition: transform .25s ease, opacity 0s linear;
  }

  &.loading {
    background: transparent;

    &:before {
      display: block;
      animation: loading .5s ease infinite alternate;
    }

    @keyframes loading {
      0% {
        transform: scale(0.1);
        box-shadow: inset 0 0 0 150px rgba(0,0,0,0.2);
      }
      50% {
        transform: scale(1);
        box-shadow: inset 0 0 0 140px golf;
      }
      100% {
        box-shadow: inset 0 0 0 0 rgba(0,0,0,0.2);
      }
    }

    .zoom-image {
      opacity: 0;
    }
  }

  .zoom-image {
    position: absolute;
    left: 0;
    top: 0;
    //width: 200%;
    max-width: none;
    transition: opacity .25s ease;
  }
}



@media(max-width: 768px) {
  .lesson-presentation-part {
    width: 100vw;
    left: 0;
    bottom: 64px;
    top: auto;
    height: calc(var(--doc-height) - 64px);
    //overflow: hidden;
    &.inZoomMode {
      overflow: hidden;
    }
    &.withoutVideo, &.half, &.halfer {
      width: 100vw;
      bottom: 64px;
    }
    &.half {
      height: calc(var(--doc-height) * 0.65 - 32px);
    }
    &.withoutVideo {
      height: calc(var(--doc-height) - 64px);
    }
    &.halfer {
      height: calc(var(--doc-height) * 0.5 - 32px);
      &.withoutVideo {
        width: 100%;
      }
    }
    &.toRight {
      right: 0;
      top: 0;
    }
  }
}

</style>
