<template>
  <section id="whiteboard" :class="{
    half: lessonModesEnabled.length === 1 && !lessonModeFull,
    halfer: lessonModesEnabled.length === 2,
    toRight: lessonModesEnabled.length === 2 && lessonModesEnabled.indexOf('blackboard') == 1,
    withoutVideo: homework,
    student: !host
  }">

    <div class="lesson-mode-header">
      <div class="d-md-inline-block d-none pr-3">
        <input type="range"  min="20" max="400" @input="zoomChangedManually" v-model="zoom">
        <div class="d-inline-block ml-2 align-middle" style="width: 30px;">{{ zoom }}</div>
      </div>
      <button class="call_room_btn"
              @click="$store.commit('expandLessonMode', 'blackboard')">
        <ExpandSvg v-if="!lessonModeFull"/><CollapseSvg v-if="lessonModeFull"/>
      </button>
      <button class="call_room_btn ml-2 d-md-inline d-none"
              v-if="!lessonModeFull && lessonModesEnabled.length > 1"
              @click="$store.commit('switchSideLessonMode', 'blackboard')">
        <SwitchSvg/>
      </button>
      <button class="call_room_btn ml-2"
              @click="$store.commit('toggleLessonMode', 'blackboard')">
        <CloseSvg/>
      </button>
    </div>

    <div class="position-relative whiteboard-prewrapper" ref="whiteboard">
      <div v-for="(coordData, uid, ind) in userCursors"
           class="other-user-cursor"
           :key="`cursor-${uid}`"
           v-if="coordData.coords"
           :style="`left: ${(coordData.coords.x * (zoom / 100) + wbCanvas.viewportTransform[4])}px; top: ${(coordData.coords.y * (zoom / 100) + wbCanvas.viewportTransform[5])}px; background: ${cursorColors[ind]}`">
        <div class="cursor-name" :style="`color: ${cursorColors[ind]}`">{{ coordData.userName }}</div>
      </div>
      <div class="h-100 overflow-scroll" :class="{ drawingDisabled: !canDraw }">
        <div id="touchback" style='touch-action:manipulation; width: 100%; height: 100%; position: absolute; z-index: 2' :class="{ 'd-block': selectedTool === 'mover', 'd-none': selectedTool != 'mover'}"></div>
        <canvas id="wbCanvas"></canvas>
      </div>
    </div>

    <header id="whiteboardHeader"
            class="whiteboard-header"
            :class="{ withDropdownOpened: dropdownShown, student: !host }">
      <div class="whiteboard-header-options whiteboard-buttons mx-auto" v-if="canDraw">
        <b-row class="align-items-center" no-gutters>
          <b-col v-if="host">
            <input id="wbBackgroundColorEl" class="whiteboardColorPicker" type="color" value="#FFFFFF" @change="bgColorChanged"/>
          </b-col>
          <b-col>
            <input id="wbDrawingColorEl" class="whiteboardColorPicker ml-2 mr-1" type="color" value="#000000"  @change="drawingColorChanged"/>
          </b-col>
          <b-col>
            <button :class="{ selected: selectedTool === 'pen' }" @click="selectTool('pen')"><PencilSvg/></button>
          </b-col>
          <b-col>
            <button :class="{ selected: selectedTool === 'pointer' }" @click="selectTool('pointer')"><PointerSvg/></button>
          </b-col>
          <b-col class="d-md-none d-block">
            <button :class="{ selected: selectedTool === 'mover' }" @click="selectTool('mover')"><MoveSvg/></button>
          </b-col>
          <b-col>
            <button @click="center"><CenterSvg/></button>
          </b-col>
          <b-col v-if="host">
            <button @click="whiteboardAction({action: 'undo'})"><UndoSvg/></button>
          </b-col>
          <b-col v-if="host">
            <button @click="whiteboardAction({action: 'redo'})"><RedoSvg/></button>
          </b-col>
          <b-col v-if="host">
            <button @click="whiteboardAddObj('imgFile')"><ImgSvg/></button>
          </b-col>
          <b-col v-if="host">
            <button @click="whiteboardAddObj('imgUrl')"><LinkSvg/></button>
          </b-col>
          <b-col>
            <button :class="{ selected: selectedTool === 'text' }" @click="selectTool('text')"><SpellCheckSvg/></button>
          </b-col>
          <b-col v-if="host">
            <button :class="{ selected: selectedTool === 'line' }" @click="selectTool('line')"><LineSvg/></button>
          </b-col>
          <b-col>
            <button :class="{ selected: selectedTool === 'rect' }" @click="selectTool('rect')"><RectSvg/></button>
          </b-col>
          <b-col>
            <button :class="{ selected: selectedTool === 'circle' }" @click="selectTool('circle')"><CircleSvg/></button>
          </b-col>
          <b-col v-if="host">
            <b-dropdown right dropup no-caret @show="dropdownShown = true" @hide="dropdownShown = false"
                        menu-class="noscrollbar small-dropdown"
                        variant="link" class="d-inline-block pointer">
              <template v-slot:button-content>
                <SaveSvg/>
              </template>
              <b-dropdown-item @click="wbCanvasSaveImg('png')">png</b-dropdown-item>
              <b-dropdown-item @click="wbCanvasSaveImg('json')">json</b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col v-if="host">
            <label class="mb-0 pointer" for="json-load"><FromFileSvg/></label>
            <input type="file" class="d-none" accept="application/json" id="json-load" @change="loadFromFile" :key="`input-file-${inputKey}`"/>
          </b-col>
          <b-col>
            <button :class="{ selected: selectedTool === 'eraser' }" @click="selectTool('eraser')"><EraserSvg/></button>
          </b-col>
          <b-col v-if="host">
            <button @click="whiteboardAction({action: 'clear'})"><TrashSvg/></button>
          </b-col>
<!--          <b-col @click="center" style="font-size: 10px; color: black">center</b-col>-->
          <!--          <b-col class="text-dark">{{ mobileMessage }}</b-col>-->
          <!--          <b-col class="text-dark">zoom: {{ zoom }}</b-col>-->
        </b-row>
      </div>
    </header>


  </section>
</template>

<script>

import { loadScript } from "vue-plugin-load-script"
import EventBus from '@/services/event-bus'
import PencilSvg from '@/assets/svg/pencil.svg'
import TrashSvg from '@/assets/svg/trash.svg'
import EraserSvg from '@/assets/svg/eraser_f.svg'
import UndoSvg from '@/assets/svg/undo.svg'
import FromFileSvg from '@/assets/svg/from_file.svg'
import RedoSvg from '@/assets/svg/redo.svg'
import PointerSvg from '@/assets/svg/pointer.svg'
import ImgSvg from '@/assets/svg/img.svg'
import LinkSvg from '@/assets/svg/link.svg'
import SpellCheckSvg from '@/assets/svg/spellcheck.svg'
import SaveSvg from '@/assets/svg/save.svg'
import LineSvg from '@/assets/svg/line.svg'
import RectSvg from '@/assets/svg/rect.svg'
import CircleSvg from '@/assets/svg/circle.svg'
import CloseSvg from "@/assets/svg/close_gray.svg"
import SwitchSvg from "@/assets/svg/switch-sides.svg"
import ExpandSvg from "@/assets/svg/expand.svg"
import CollapseSvg from "@/assets/svg/collapse.svg"
import MoveSvg from "@/assets/svg/move2.svg"
import CenterSvg from "@/assets/svg/center.svg"

import Vue from 'vue'
import { mapState } from "vuex"
import { LessonsService } from "../../../services/api.service"
import { cloneIcon, deleteIcon, cursorColors, renderIcon, standardControlSettings } from "../../../services/blackboard.helper"

export default {
  name: "MirotalkBlackboard",
  components: {
    PencilSvg,
    TrashSvg,
    UndoSvg,
    RedoSvg,
    EraserSvg,
    PointerSvg,
    ImgSvg,
    LinkSvg,
    SpellCheckSvg,
    SaveSvg,
    LineSvg,
    RectSvg,
    FromFileSvg,
    CircleSvg,
    CloseSvg,
    SwitchSvg,
    ExpandSvg,
    CollapseSvg,
    MoveSvg,
    CenterSvg
  },
  computed: {
    userId() {
      return this.$store.state.user ? this.$store.state.user.id : 'demo'
    },
    userName() {
      return this.$store.state.user ? this.$store.state.user.name : 'demo'
    },
    ...mapState({
      lessonModesEnabled: state => state.call.lessonModesEnabled,
      lessonModeFull: state => state.call.lessonModeFull,
      socket: state => state.call.socket,
      homework: state => state.call.isHomework,
      room: state => state.call.room,
      host: state => state.call.host,
      isKeyboardOpen: state => state.isKeyboardOpen,
      isMobile: state => state.isMobile,
      followed: state => state.call.followed,
      viewMode: state => state.call.viewMode,
    }),
    blackboardVisible() {
      return this.lessonModesEnabled.includes('blackboard')
    },
    canDraw() {
      return this.host || this.followed || this.viewMode !== 'lesson'
    }
  },
  data() {
    return {
      swalBackground: 'linear-gradient(to left, #1f1e1e, #000000)',
      wbCanvas: null,
      wbImageInput: 'image/*',
      wbIsRedoing: false,
      selectedTool: null,
      timer: null,
      wbPop: [],
      userCursors: {},
      cursorColors: cursorColors,
      dropdownShown: false,
      inputKey: 1,
      zoom: 100,
      lastPosX: 0,
      lastPosY: 0,
      mobileMessage: ''
    }
  },
  async mounted() {
    await loadScript('/fabric2.min.js')
    await loadScript('/hammer.min.js')
    this.setupWhiteboard()
    window.addEventListener('resize', this.styling)
    await this.loadFromDB()
    await this.$nextTick()
    this.checkPermissions()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.styling)
  },
  methods: {
    styling() {
      this.setupWhiteboardCanvasSize()
    },
    selectTool(tool) {
      if(!this.canDraw) return
      this.selectedTool = tool
    },
    setupWhiteboard() {
      this.setupWhiteboardCanvas()
      this.setupWhiteboardCanvasSize()
      this.setupWhiteboardLocalListeners()
      this.setUpHammer()
      EventBus.$on('wbCanvasToJson', (json) => {
        this.JsonToWbCanvas(json)
      })
      EventBus.$on('blackboardCursorMove', (coords) => {
        this.$set(this.userCursors, coords.userId, coords)
      })
    },
    setupWhiteboardCanvas() {
      this.wbCanvas = new window.fabric.Canvas('wbCanvas', {
        // selection: false,
        // controlsAboveOverlay: true,
        // centeredScaling: true,
        // interactive: false,
        allowTouchScrolling: true
      })
      // this.wbCanvas = new window.fabric.Canvas('wbCanvas')
      this.wbCanvas.freeDrawingBrush.color = '#000000'
      this.wbCanvas.freeDrawingBrush.width = 2
      this.selectedTool = !this.host && this.isMobile ? 'mover' : 'pen'
      if(!this.canDraw) this.selectedTool = null
      let _this = this
      this.wbCanvas.on("mouse:wheel", function(opt) {
        opt.e.preventDefault()
        opt.e.stopPropagation()
        if (opt.e.ctrlKey) { // means pinching
          var delta = opt.e.deltaY * 10 // sensitivity
          var zoom = _this.wbCanvas.getZoom()
          if (zoom > 10) zoom = 10
          if (zoom < 0.2) zoom = 0.2
          zoom *= 0.999 ** delta;
          _this.zoom = Math.round(zoom * 100)
          _this.wbCanvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom)
        } else { // panning
          const e = opt.e
          let vpt = this.viewportTransform
          vpt[4] += -e.deltaX
          vpt[5] += -e.deltaY
          this.requestRenderAll()
        }
      })
      var deleteImg = document.createElement('img')
      deleteImg.src = deleteIcon()
      window.fabric.Object.prototype.controls.deleteControl = new window.fabric.Control({
        ...standardControlSettings,
        x: 0.5,
        offsetX: 16,
        mouseUpHandler: this.deleteObject,
        render: renderIcon(deleteImg),
      });
      var cloneImg = document.createElement('img')
      cloneImg.src = cloneIcon()
      window.fabric.Object.prototype.controls.clone = new window.fabric.Control({
        ...standardControlSettings,
        mouseUpHandler: this.cloneObject,
        render: renderIcon(cloneImg),
      });
    },
    zoomChangedManually() {
      let obj = this.$refs.whiteboard
      this.wbCanvas.zoomToPoint({ x: obj.clientWidth / 2, y: obj.clientHeight / 2 }, (this.zoom / 100))
    },
    setupWhiteboardCanvasSize() {
      let obj = this.$refs.whiteboard
      this.wbCanvas.setWidth(obj.clientWidth)
      this.wbCanvas.setHeight(obj.clientHeight)
      this.wbCanvas.setZoom(1)
      this.wbCanvas.calcOffset()
      this.wbCanvas.renderAll()
    },
    setUpHammer() {
      let _this = this
      let localZoom = 100
      let localDeltaX = 0
      let localDeltaY = 0

      const myUpperCanvasEl = document.getElementById("touchback")
      const myUCanManager = new window.Hammer(myUpperCanvasEl)
      myUCanManager.get('pinch').set({ enable: true })

      myUCanManager.on('pinchstart', () => {
        localZoom = _this.zoom
      })

      myUCanManager.on('pinchin pinchout', function(e) {
        var zoom = localZoom * e.scale
        if (zoom > 2000) zoom = 2000
        if (zoom < 20) zoom = 20
        _this.zoom = zoom
        _this.wbCanvas.zoomToPoint({ x: e.center.x, y: e.center.y }, (_this.zoom / 100))
      })

      myUCanManager.on('pan', function(e) {
        let vpt = _this.wbCanvas.viewportTransform
        vpt[4] += (e.deltaX - localDeltaX)
        vpt[5] += (e.deltaY - localDeltaY)
        localDeltaX = e.deltaX
        localDeltaY = e.deltaY
        _this.wbCanvas.requestRenderAll()
      });

      myUCanManager.on('panend', function() {
        localDeltaY = 0
        localDeltaX = 0
      })

    },
    whiteboardAddObj(type) {
      if(!this.canDraw) return
      let _this = this
      switch (type) {
        case 'imgUrl':
          Vue.swal.fire({
            title: 'Image URL',
            input: 'text',
            showCancelButton: true,
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result.isConfirmed) {
              let wbCanvasImgURL = result.value;
              if (this.isImageURL(wbCanvasImgURL)) {
                window.fabric.Image.fromURL(wbCanvasImgURL, function (myImg) {
                  _this.addWbCanvasObj(myImg);
                });
              } else {
                console.log('The URL is not a valid image');
              }
            }
          });
          break;
        case 'imgFile':
          Vue.swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: 'Select the image',
            input: 'file',
            inputAttributes: {
              accept: this.wbImageInput,
              'aria-label': 'Select the image',
            },
            showDenyButton: true,
            confirmButtonText: `OK`,
            denyButtonText: `Cancel`,
          }).then((result) => {
            if (result.isConfirmed) {
              let wbCanvasImg = result.value
              if (wbCanvasImg && wbCanvasImg.size > 0) {
                let reader = new FileReader()
                reader.onload = function (event) {
                  let imgObj = new Image()
                  imgObj.src = event.target.result
                  imgObj.onload = function () {
                    let image = new window.fabric.Image(imgObj)
                    image.set({ top: 0, left: 0 }).scale(0.3)
                    _this.addWbCanvasObj(image)
                  };
                };
                reader.readAsDataURL(wbCanvasImg)
              } else {
                console.log('File not selected or empty')
              }
            }
          });
          break;
      }
    },
    addWbCanvasObj(obj) {
      if (obj) {
        this.wbCanvas.add(obj).setActiveObject(obj)
        this.wbCanvasToJson()
      }
    },
    setupWhiteboardLocalListeners() {
      let _this = this
      this.wbCanvas.on('mouse:down', function (e) {
        _this.mouseDown(e)
      });
      this.wbCanvas.on('mouse:up', function () {
        _this.mouseUp()
      });
      this.wbCanvas.on('mouse:move', function (e) {
        _this.mouseMove(e)
      });
      this.wbCanvas.on('mouse:out', function () {
        _this.mouseLeave()
      });
      this.wbCanvas.on('object:added', function () {
        _this.objectAdded()
      });
    },
    mouseDown(e) {
      this.processCanvasClick(this.getCoordsFromMouse(e))
      if (this.selectedTool === 'eraser' && e.target) {
        this.wbCanvas.remove(e.target)
      }
    },
    mouseUp() {
      this.wbCanvasToJson()
    },
    mouseLeave() {
      if(this.socket) {
        this.socket.emit('customevent', 'blackboardCursorMove', this.room, {
          coords: null,
          zoom: this.zoom,
          userId: this.userId,
          userName: this.userName
        })
      }
    },
    mouseMove(e) {
      if(this.socket) {
        this.socket.emit('customevent', 'blackboardCursorMove', this.room, {
          coords: this.getCoordsFromMouse(e),
          zoom: this.zoom,
          userId: this.userId,
          userName: this.userName
        })
      }
      if (this.selectedTool === 'eraser') {
        this.wbCanvas.hoverCursor = 'not-allowed'
      } else {
        this.wbCanvas.hoverCursor = 'move'
      }
    },
    getCoordsFromMouse(e) {
      let coords = e.e.touches ? e.e.touches[0] : e.e;
      return this.wbCanvas.getPointer(coords);
    },
    objectAdded() {
      if (!this.wbIsRedoing) this.wbPop = []
      this.wbIsRedoing = false
    },
    isImageURL(url) {
      return url.match(/\.(jpeg|jpg|gif|png|tiff|bmp)$/) != null;
    },
    bgColorChanged(e) {
      let data = {
        action: 'bgcolor',
        color: e.target.value,
      };
      this.whiteboardAction(data)
    },
    drawingColorChanged(e) {
      this.wbCanvas.freeDrawingBrush.color = e.target.value
    },
    wbCanvasBackgroundColor(color) {
      this.wbCanvas.setBackgroundColor(color)
      this.wbCanvas.renderAll()
    },
    wbCanvasUndo() {
      if (this.wbCanvas._objects.length > 0) {
        this.wbPop.push(this.wbCanvas._objects.pop())
        this.wbCanvas.renderAll()
      }
    },
    wbCanvasRedo() {
      if (this.wbPop.length > 0) {
        this.wbIsRedoing = true
        this.wbCanvas.add(this.wbPop.pop())
      }
    },
    whiteboardAction(data, emit = true) {
      if(!this.canDraw) return
      switch (data.action) {
        case 'bgcolor':
          this.wbCanvasBackgroundColor(data.color)
          break;
        case 'undo':
          this.wbCanvasUndo()
          break;
        case 'redo':
          this.wbCanvasRedo()
          break;
        case 'clear':
          this.wbCanvas.clear()
          break;
      }
      if(emit) this.wbCanvasToJson()
    },
    wbCanvasSaveImg(type = 'png') {
      const dataNow = this.getDataTimeString()
      if(type === 'png') {
        const dataURL = this.wbCanvas.toDataURL({
          width: this.wbCanvas.getWidth(),
          height: this.wbCanvas.getHeight(),
          left: 0,
          top: 0,
          format: 'png',
        });
        const fileName = `whiteboard-${dataNow}.png`;
        this.saveDataToFile(dataURL, fileName);
      }
      if(type === 'json') {
        let wbCanvasJson = JSON.stringify(this.wbCanvas.toJSON())
        const data = new Blob([wbCanvasJson], {type: 'text/plain'});
        const fileUrl = window.URL.createObjectURL(data);
        const fileName = `whiteboard-${dataNow}.json`;
        this.saveDataToFile(fileUrl, fileName);
      }
    },
    wbCanvasToJson() {
      if(!this.canDraw) return
      let wbCanvasJson = JSON.stringify(this.wbCanvas.toJSON())
      this.$store.commit('setBlackboardJson', wbCanvasJson)
      this.saveToDB()
      if(this.socket) {
        this.socket.emit('customevent', 'wbCanvasToJson', this.room, wbCanvasJson)
      }
    },
    JsonToWbCanvas(json) {
      this.wbCanvas.loadFromJSON(json)
      this.wbCanvas.renderAll()
      this.$nextTick(() => {
        this.checkPermissions()
      })
      // this.wbCanvasToJson()
    },
    getDataTimeString() {
      const d = new Date()
      const date = d.toISOString().split('T')[0]
      const time = d.toTimeString().split(' ')[0]
      return `${date}-${time}`
    },
    saveDataToFile(dataURL, fileName) {
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = dataURL
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      setTimeout(() => {
        document.body.removeChild(a)
        window.URL.revokeObjectURL(dataURL)
      }, 100)
    },
    saveToDB() {
      if(!this.canDraw) return
      if(this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        LessonsService.saveBlackboard(this.room, this.wbCanvas.toJSON())
      }, 250)
    },
    async loadFromDB() {
      if(!this.room) return
      const res = await LessonsService.getBlackboard(this.room)
      if(res.data && res.data.data && res.data.data.blackboard_result) {
        this.JsonToWbCanvas(res.data.data.blackboard_result)
        this.wbCanvasToJson()
      }
    },
    loadFromFile(e) {
      if(!this.canDraw) return
      var reader = new FileReader();
      reader.readAsText(e.target.files[0], "UTF-8")
      let _this = this
      reader.onload = function (evt) {
        _this.JsonToWbCanvas(evt.target.result)
        _this.inputKey++
        _this.wbCanvasToJson()
      }
    },
    processCanvasClick(coords) {
      if(!this.selectedTool || !this.canDraw) return
      switch (this.selectedTool) {
        case 'text': {
          const text = new window.fabric.IText('', {
            top: coords.y,
            left: coords.x,
            fontFamily: 'Montserrat',
            fill: this.wbCanvas.freeDrawingBrush.color,
            strokeWidth: 0,
            stroke: this.wbCanvas.freeDrawingBrush.color,
            editable: true
          });
          this.addWbCanvasObj(text)
          text.enterEditing()
          this.selectedTool = null
          break;
        }
        case 'line': {
          const line = new window.fabric.Line([50, 100, 200, 200], {
            top: coords.y,
            left: coords.x,
            fill: this.wbCanvas.freeDrawingBrush.color,
            strokeWidth: this.wbCanvas.freeDrawingBrush.width,
            stroke: this.wbCanvas.freeDrawingBrush.color,
          });
          this.addWbCanvasObj(line)
          this.selectedTool = 'pointer'
          break;
        }
        case 'circle': {
          const circle = new window.fabric.Circle({
            top: coords.y,
            left: coords.x,
            radius: 50,
            fill: 'transparent',
            stroke: this.wbCanvas.freeDrawingBrush.color,
            strokeWidth: this.wbCanvas.freeDrawingBrush.width,
          });
          this.addWbCanvasObj(circle);
          this.selectedTool = 'pointer'
          break;
        }
        case 'rect': {
          const rect = new window.fabric.Rect({
            top: coords.y,
            left: coords.x,
            width: 150,
            height: 100,
            fill: 'transparent',
            stroke: this.wbCanvas.freeDrawingBrush.color,
            strokeWidth: this.wbCanvas.freeDrawingBrush.width,
          });
          this.addWbCanvasObj(rect)
          this.selectedTool = 'pointer'
          break;
        }
      }
    },
    cloneObject(eventData, transform) {
      if(!this.canDraw) return
      const target = transform.target
      const canvas = target.canvas
      let leftAdj = 0
      let topAdj = 0
      let activeObjects = canvas.getActiveObject()
      if(activeObjects._objects) {
        activeObjects = activeObjects._objects
        leftAdj = target.left
        topAdj = target.top
      } else {
        activeObjects = [activeObjects]
      }
      activeObjects.forEach((o) => {
        o.clone(function (cloned) {
          cloned.left += (100 + leftAdj)
          cloned.top += (50 + topAdj)
          console.log(cloned)
          canvas.add(cloned)
        })
      })
    },
    center() {
      let vpt = this.wbCanvas.viewportTransform
      let left = 0
      let top = 0
      const zoomAbs = this.zoom / 100
      let objs = this.wbCanvas.getObjects()
      objs.forEach(function(item) {
        left = Math.max(left, (item.left + item.width))
        top = Math.max(top, (item.top + item.height))
      })
      const _this = this
      const finalLeft = -((left - this.wbCanvas.width / (zoomAbs)) / 2) * zoomAbs
      const finalTop = -((top - this.wbCanvas.height / (zoomAbs)) / 2) * zoomAbs
      window.fabric.util.animate({
        startValue: [vpt[4], vpt[5]],
        endValue: [finalLeft, finalTop],
        duration: 300,
        onChange: function(v) {
          vpt[4] = v[0]
          vpt[5] = v[1]
          _this.wbCanvas.renderAll()
        },
        onComplete: function() {
          _this.wbCanvas.renderAll()
        }
      })
      this.wbCanvas.requestRenderAll()
    },
    deleteObject(eventData, transform) {
      if(!this.canDraw) return
      const target = transform.target
      const canvas = target.canvas
      let activeObjects = canvas.getActiveObject()
      if(activeObjects._objects) {
        activeObjects = activeObjects._objects
      } else {
        activeObjects = [activeObjects]
      }
      activeObjects.forEach((o) => {
        canvas.remove(o)
      })
      canvas.discardActiveObject()
      canvas.requestRenderAll()
    },
    checkPermissions() {
      const val = this.canDraw
      if(this.wbCanvas) {
        this.wbCanvas.forEachObject(function(object) {
          object.editable = val
          object.selectable = val
        })
      }
    }
  },
  watch: {
    lessonModesEnabled: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.setupWhiteboardCanvasSize()
        })
      }
    },
    blackboardVisible() {
      this.checkPermissions()
    },
    canDraw: {
      handler(val) {
        if(!this.wbCanvas) return
        this.wbCanvas.isDrawingMode = (this.selectedTool === 'pen' && val)
        this.checkPermissions()
      },
      immediate: true
    },
    selectedTool(val) {
      if(!this.wbCanvas) return
      this.wbCanvas.isDrawingMode = (val === 'pen' && this.canDraw)
    }
  }
}
</script>

<style lang="scss" scoped>

#whiteboard {
  z-index: 4;
  position: fixed;
  margin: auto;
  left: 72px;
  top: 0;
  width: calc(100vw - 72px - 145px);
  background: #fff;
  overflow: hidden;
  &.withoutVideo {
    width: calc(100vw - 72px);
  }
  &.half {
    width: calc(50vw - 36px);
  }
  &.halfer {
    width: calc(50vw - 36px - 72.5px);
    &.withoutVideo {
      width: calc(50vw - 36px);
    }
  }
  &.toRight {
    left: auto;
    right: 145px;
    border-left: 1px solid #E8EFFA;
    &.withoutVideo {
      right: 0;
    }
  }
}
.other-user-cursor {
  position: absolute;
  width: 5px;
  height: 5px;
  margin-left: -2.5px;
  margin-top: -2.5px;
  border-radius: 100%;
  line-height: 1;
  display: inline-block;
  //display: none;
  .cursor-name {
    font-size: 8px;
    white-space: nowrap;
    position: absolute;
    transform: translateX(-50%);
    margin-top: 6px;
  }
}

#wbCanvas, .whiteboard-prewrapper {
  height: calc(100vh - 110px);
}

.whiteboard-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  height: 50px;
  color: white;
  overflow-x: scroll;
  border-top: 1px solid $border-color2;
  .whiteboard-buttons {
    > .row {
      flex-wrap: nowrap;
      white-space: nowrap;
    }
  }
  &.withDropdownOpened {
    overflow-x: visible;
    .whiteboard-buttons {
      > .row {
        flex-wrap: wrap;
      }
    }
  }
}

.whiteboardColorPicker {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: solid 1px $text-gray4;
  display: block;
}
.whiteboard-buttons {
  .dropdown {
    padding: 1px 5px;
  }
  button, label {
    background: none;
    border: none;
    padding: 1px 5px;
    &.selected {
      svg {
        fill: $text-success;
      }
    }
    svg {
      width: 20px;
      fill: $text-gray4;
    }
    &.stroked {
      svg {
        stroke: $text-gray4;
        fill: transparent;
        stroke-width: 4px;
      }
    }
  }
}
.whiteboardColorPicker:hover {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.whiteboardColorPicker::-webkit-color-swatch {
  border: none;
  border-radius: 20px;
  padding: 0;
}
.whiteboardColorPicker::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 20px;
  padding: 0;
}
.whiteboardColorPicker::-moz-color-swatch {
  border: none;
  border-radius: 20px;
  padding: 0;
}
.whiteboardColorPicker::-moz-color-swatch-wrapper {
  border: none;
  border-radius: 20px;
  padding: 0;
}
.whiteboardColorPicker::color-swatch {
  border: none;
  border-radius: 20px;
  padding: 0;
}
.whiteboardColorPicker::color-swatch-wrapper {
  border: none;
  border-radius: 20px;
  padding: 0;
}
.drawingDisabled {
  //pointer-events: none !important;
}

@media(max-width: 768px) {
  #whiteboard {
    width: 100vw;
    left: 0;
    top: auto;
    height: calc(var(--doc-height) - 64px);
    &.withoutVideo, &.half, &.halfer {
      width: 100vw;
      bottom: 64px;
    }
    &.half {
      height: calc(var(--doc-height) * 0.65 - 32px);
    }
    &.withoutVideo {
      height: calc(var(--doc-height) - 64px);
    }
    &.halfer {
      height: calc(var(--doc-height) * 0.5 - 32px);
      &.withoutVideo {
        width: 100%;
      }
    }
    &.toRight {
      right: 0;
      top: 0;
      bottom: auto;
      border-left: none;
    }
  }
  .whiteboard-prewrapper {
    height: calc(var(--doc-height) - 172px);
  }
  .half {
    .whiteboard-prewrapper {
      height: calc(var(--doc-height) * 0.65 - 140px);
    }
  }
  .withoutVideo {
    .whiteboard-prewrapper {
      height: calc(var(--doc-height) - 172px);
    }
  }
  .halfer {
    .whiteboard-prewrapper {
      height: calc(var(--doc-height) * 0.5 - 140px);
    }
  }
  .whiteboard-header {
    &.student {
      position: absolute;
      top: 7px;
      border-top: 0;
      left: 0;
    }
  }
  #whiteboard {
    &.student {
      .whiteboard-prewrapper {
        height: calc(var(--doc-height) - 122px);
      }
      .half {
        .whiteboard-prewrapper {
          height: calc(var(--doc-height) * 0.65 - 90px);
        }
      }
      .withoutVideo {
        .whiteboard-prewrapper {
          height: calc(var(--doc-height) - 122px);
        }
      }
      .halfer {
        .whiteboard-prewrapper {
          height: calc(var(--doc-height) * 0.5 - 90px);
        }
      }
    }
  }

}
</style>
