<template>
  <div class="finished_wrapper">
    <div class="finished_block">
      <div class="finished_header">
        <finishSvg />
        <h3>{{ $t('finished') }}</h3>
      </div>
      <div class="finished_body">
        <h3>{{$t('lesson')}} {{ material.title }}</h3>
        
        <template v-if="host && view == 'lesson'">
          <span v-if="viewMode === 'homeworkReview'">{{ $t('finish_homework_review') }}?</span>  
          <span v-else>{{ $t('finish_lesson_and_rate_student') }}?</span>  
        </template>
        
      </div>
      <div class="finished_footer">
        <button class="btn-themed" @click="finished">
          {{ viewMode === 'homeworkReview' ? $t('finish') : $t('finish_lesson') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import finishSvg from '@/assets/svg/finish.svg'
import {mapState} from "vuex"
export default {
  name: "LessonFinished",
  props: {
    material: {},
    view: { type: String, default: 'lesson' },
    host: { type: Boolean, default: false },
  },
  components: {
    finishSvg
  },
  data() {
    return {}
  },
  methods: {
    finished() {
      this.$emit('finishedClick')
    },
  },
  computed: {
    ...mapState({
      viewMode: state => state.call.viewMode,
    })
  }
}
</script>

<style lang="scss" scoped>

.finished_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 130px);
  background: #FFFFFF;
  border-radius: 16px;
  @media(max-width: 768px) {
    height: auto;
  }
}
.finished_block {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 512px;
  min-width: 260px;
  @media(min-width: 992px) {
    min-width: 512px;
  }
  text-align: center;
  background: #fff;
  box-shadow: 0px 0px 22px rgba(74, 75, 84, 0.15);
  border-radius: 16px;

  .finished_header {
    h3 {
      font-weight: 700;
      font-size: 28px;
      padding-top: 12px;
    }
  }

  .finished_body {
    padding: 32px 0;
    h3 {
      font-weight: 600;
      font-size: 18px;
      padding-bottom: 8px;
    }

    span {
      color: #7E828C;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
</style>
