<template>
  <div :class="{
        'host': udata.host,
    } ">
    <div class="call-participant-teaser"
         :class="{
             withoutvideo: !udata.video,
             selected: selectedUser === udata.dbUserId,
             fullScreen,
             screenSharing,
             watchingOtherScreenSharing: (udata.remotestream && udata.remotestream.additional)
             }">
      <div class="video-wrapper" :key="`video-${uid}-${$store.state.specialkey}`">
        <div v-if="!udata.video" class="no-video">
          <div v-if="user">
            <b-row class="align-items-center">
              <b-col cols="auto">
                <LetterIcon :obj="user"
                            :title="user.name"
                            class="student-icon icon-chat"/>
              </b-col>
              <b-col>
                {{ user.name }}
              </b-col>
            </b-row>
          </div>
        </div>
        <video v-if="udata.remotestream"
               autoplay
               playsinline
               :class="{
                  'd-none': !udata.video && !(udata.remotestream && udata.remotestream.additional),
                  'hostvideotag': udata.host,
                  'studentvideotag': !udata.host,
                  'othersvideotag': !self
               }"
               class="p2pvideotag"
               :muted="self"
               ref="videodom"
               @loadeddata="loaded"
               :srcObject.prop="udata.remotestream">
        </video>
        <b-spinner variant="primary" v-if="loading" label="Spinning" class="video-loader"></b-spinner>
        <div class="video-indicators">
          <div class="indicator" :class="{ active: !fullScreen }">
            <b-icon-fullscreen class="d-inline-block" v-if="!fullScreen" @click.stop="videoFullScreen"></b-icon-fullscreen>
            <b-icon-fullscreen-exit class="d-inline-block" v-if="fullScreen" @click.stop="videoFullScreen"></b-icon-fullscreen-exit>
          </div>
          <div class="indicator ml-1" v-if="pictureInPictureSupported" :class="{ active: !pictureInPictureWorking }">
            <b-icon-box-arrow-in-down-right class="d-inline-block" @click.stop="enterPictureInPicture"></b-icon-box-arrow-in-down-right>
          </div>
          <div class="indicator ml-1" @click.stop="audioClick" :class="{ active: udata.audio }">
            <b-icon-mic class="d-inline-block" v-if="udata.audio"></b-icon-mic>
            <b-icon-mic-mute  class="d-inline-block" v-if="!udata.audio"></b-icon-mic-mute>
          </div>
          <div class="ml-1 indicator" @click.stop="videoClick" :class="{ active: udata.video }">
            <b-icon-camera-video class="d-inline-block"  v-if="udata.video"></b-icon-camera-video>
            <b-icon-camera-video-off class="d-inline-block"  v-if="!udata.video"></b-icon-camera-video-off>
          </div>
          <div class="ml-1 indicator screenShareIndicator" v-if="local" @click.stop="screenShare" :class="{ active: !screenSharing }">
            <b-icon-display class="d-inline-block" v-if="screenSharing"></b-icon-display>
            <b-icon-display class="d-inline-block" v-if="!screenSharing"></b-icon-display>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// import Loader from '@/views/parts/general/Loader.vue'
import LetterIcon from '@/views/parts/general/LetterIcon'
import EventBus from '@/services/event-bus'
import { mapState } from 'vuex'
// import MicSvg from "@/assets/svg/mic.svg"

export default {
  components: {
    // MicSvg
    // Loader,
    LetterIcon
  },
  data() {
    return {
      loading: true,
      fullScreen: false,
      pictureInPictureWorking: false
    }
  },
  props: {
    uid: {
      type: Number
    },
    udata: {
      type: Object
    },
    user: {
      type: Object,
      default: null
    },
    numOfUsers: {
      type: Number,
      required: true
    },
    modal: {
      type: Boolean,
      default: false,
    },
    local: {
      type: Boolean,
      default: false,
    },
    screenSharing: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    loaded() {
      this.loading = false
    },
    audioClick() {
      if(this.self) {
        EventBus.$emit('mute')
        return
      }
      if(this.host) {
        EventBus.$emit('muteuser', this.udata.connection.peer)
      }
    },
    videoClick() {
      if(this.self) {
        EventBus.$emit('videomute')
        return
      }
      if(this.host) {
        EventBus.$emit('videomuteuser', this.udata.connection.peer)
      }
    },
    screenShare() {
      this.$emit('toggleScreenShare')
    },
    videoFullScreen() {
      this.fullScreen = !this.fullScreen
    },
    enterPictureInPicture() {
      if(!this.pictureInPictureSupported) return
      const $el = this.$refs.videodom
      if(document.pictureInPictureElement) {
        document.exitPictureInPicture()
        if(this.pictureInPictureWorking) return
      }
      if($el) {
        $el.requestPictureInPicture()
        setTimeout(() => this.pictureInPictureWorking = true, 50)
      }

    },
    pictureInPictureLeft() {
      this.pictureInPictureWorking = false
    }
  },
  computed: {
    self() {
      return this.uid === this.$store.state.user.id
    },
    ...mapState({
      host: state => state.call.host,
      selectedUser: state => state.call.selectedUser,
    }),
    pictureInPictureSupported() {
      return document.pictureInPictureEnabled
    },
  },
  created() {
    window.addEventListener('leavepictureinpicture', this.pictureInPictureLeft);
  },
  mounted() {
    console.log(this.udata)
  },

}
</script>

<style scoped lang="scss">
.call-participant-teaser {
  border-radius: 16px;
  &.withoutvideo {
    border: 1px solid $border-gray;
  }
  &.selected, &.selected.withoutvideo {
    border: 3px solid #FFD157;
  }
  &.watchingOtherScreenSharing, &.screenSharing {
    video {
      object-fit: contain;
    }
  }
  &.fullScreen {
    position: fixed;
    z-index: 5;
    width: calc(100% - 83px);
    left: 78px;
    top: 5px;
    height: calc(100% - 32px);
    .video-wrapper {
      padding-bottom: calc(100vh - 10px);
    }
  }

  .video-loader {
    position: absolute;
    top: 50%;
    margin-top: -16px;
    left: 50%;
    margin-left: -16px;
  }
  .video-indicators {
    position: absolute;
    top: 8px;
    right: 8px;
    .indicator {
      font-size: 12px;
      border-radius: 100%;
      color: #FF2F2F;
      background: #fff;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      display: inline-block;
      cursor: pointer;
      &.active {
        color: #fff;
        background: rgba(0,0,0,0.45);
        display: none;
      }
      &.screenShareIndicator {
        display: none;
      }
    }
  }
  video {
    background: #fff;
    border-radius: 14px;

    :focus {
      outline: none;
      border: 0;
    }
    &.fullScreen {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  .no-video {
    background: $gray-back;
  }
  &:hover {
    .video-indicators {
      .indicator, .indicator.active {
        display: inline-block;
      }
    }
  }
}

.host .call-participant-teaser {
  .video-indicators {
    .indicator {
      font-size: 16px;
      width: 38px;
      height: 38px;
      line-height: 38px;
    }
  }
}

@media(max-width: 768px) {
  .call-participant-teaser {
    &.fullScreen {
      position: fixed;
      z-index: 5;
      width: calc(100% - 10px);
      left: 5px;
      top: 5px;
      height: calc(100% - 75px);
      .video-wrapper {
        padding-bottom: calc(100vh - 75px);
      }
    }
  }
}
</style>






