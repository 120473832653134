var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.material && _vm.material.presentation_files && _vm.material.presentation_files.length)?_c('div',{ref:"presentationcanvas",staticClass:"lesson-presentation-part",class:{
         shown: _vm.lessonModesEnabled.includes('presentation'),
         half: _vm.lessonModesEnabled.length === 1 && !_vm.lessonModeFull,
         halfer: _vm.lessonModesEnabled.length === 2,
         toRight: _vm.lessonModesEnabled.length === 2 && _vm.lessonModesEnabled.indexOf('presentation') == 1,
         withoutVideo: _vm.view === 'selfstudy' || _vm.homework,
         host: _vm.host,
         inZoomMode: _vm.zoomMode
       },attrs:{"id":"presentationcanvas"},on:{"mousemove":_vm.mousemove,"scroll":_vm.onscroll}},[_c('div',{staticClass:"lesson-mode-header"},[_c('button',{staticClass:"call_room_btn",on:{"click":function($event){return _vm.$store.commit('expandLessonMode', 'presentation')}}},[(!_vm.lessonModeFull)?_c('ExpandSvg'):_vm._e(),(_vm.lessonModeFull)?_c('CollapseSvg'):_vm._e()],1),_c('button',{staticClass:"call_room_btn ml-2",class:{ active: _vm.zoomMode },on:{"click":function($event){_vm.zoomMode = !_vm.zoomMode; _vm.zoomDisabled = false}}},[_c('MagnifierSvg',{attrs:{"fill":"#9499A6"}})],1),(!_vm.lessonModeFull && _vm.lessonModesEnabled.length > 1)?_c('button',{staticClass:"call_room_btn ml-2 d-md-inline d-none",on:{"click":function($event){return _vm.$store.commit('switchSideLessonMode', 'presentation')}}},[_c('SwitchSvg')],1):_vm._e(),_c('button',{staticClass:"call_room_btn ml-2",on:{"click":function($event){return _vm.$store.commit('toggleLessonMode', 'presentation')}}},[_c('CloseSvg')],1)]),_c('div',{staticClass:"slide-part"},[_vm._l((_vm.material.presentation_files),function(m,mind){return _c('div',{key:("pr-" + mind)},_vm._l((m.path),function(p,pind){return _c('div',{key:("pr-" + mind + "-" + pind),ref:("pdf-" + mind + "-" + pind),refInFor:true,staticClass:"pdf-container position-relative"},[(mind > 0)?_c('hr',{staticClass:"my-3"}):_vm._e(),_c('img',{ref:("img-" + mind + "-" + pind),refInFor:true,staticClass:"img presentation-img",attrs:{"src":p,"alt":"","onContextMenu":"return false;"},on:{"mouseover":function($event){
       var i = arguments.length, argsArray = Array(i);
       while ( i-- ) argsArray[i] = arguments[i];
return _vm.enterImage.apply(void 0, [ mind, pind ].concat( argsArray ))},"touchstart":function($event){
       var i = arguments.length, argsArray = Array(i);
       while ( i-- ) argsArray[i] = arguments[i];
return _vm.enterImage.apply(void 0, [ mind, pind ].concat( argsArray ))},"mouseout":function($event){
       var i = arguments.length, argsArray = Array(i);
       while ( i-- ) argsArray[i] = arguments[i];
return _vm.leaveImage.apply(void 0, [ mind, pind ].concat( argsArray ))},"touchend":function($event){
       var i = arguments.length, argsArray = Array(i);
       while ( i-- ) argsArray[i] = arguments[i];
return _vm.leaveImage.apply(void 0, [ mind, pind ].concat( argsArray ))},"mousemove":function($event){
       var i = arguments.length, argsArray = Array(i);
       while ( i-- ) argsArray[i] = arguments[i];
return _vm.moveImage.apply(void 0, [ mind, pind ].concat( argsArray ))},"touchmove":function($event){
       var i = arguments.length, argsArray = Array(i);
       while ( i-- ) argsArray[i] = arguments[i];
return _vm.moveImage.apply(void 0, [ mind, pind ].concat( argsArray ))}}}),(_vm.zoomMode && _vm.currentZoomIndex == (mind + "-" + pind))?_c('div',{ref:("zoom-" + mind + "-" + pind),refInFor:true,staticClass:"zoom",style:(("left: " + _vm.zoomLeft + "px; top: " + _vm.zoomTop + "px")),on:{"click":_vm.zoomFreeze}},[_c('img',{ref:("zoom-image-" + mind + "-" + pind),refInFor:true,staticClass:"zoom-image",style:(("left: " + _vm.zoomImgLeft + "px; top: " + _vm.zoomImgTop + "px; width: " + _vm.imgW + "px")),attrs:{"src":p,"alt":""}})]):_vm._e()])}),0)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.cursorShow),expression:"cursorShow"}],ref:"cursorp",style:({
              left: _vm.cursorLeft+'px',
              top: _vm.cursorTop+'px',
              'margin-left': (-_vm.currentSize/2)+'px',
              'margin-top': (-_vm.currentSize/2)+'px',
              width: _vm.currentSize+'px',
              height: _vm.currentSize+'px'
        }),attrs:{"id":"cursorp"}})],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }