<template>
  <div>
    <div v-for="(g, gind) in groups" :key="`g-${gind}`">
      <template v-if="groupIndex == gind">
        <div class="words-list">
        <span  v-for="(c, cind) in g"
               :key="`crd-${indexed(cind, gind)}`"
               @click="selectWord(c)"
               :class="{
                  active: selectedWord && selectedWord.word === c.word,
                  used: cardUsed(c)
               }"
               class="drag-block pointer">{{ c.word }}
        </span>
        </div>
        <div class="words-cards">
          <b-row class="justify-content-center">
            <b-col lg="4"
                   md="6"
                   class="mb-3"
                   v-for="(c, cind) in shuffledGroups[gind]"
                   :key="`drg-${indexed(cind, gind)}`">
              <WordCardTeaser :word="c"
                              @click.native="dropWord(indexed(cind, gind))"
                              :show-answer="groupResponded"
                              :response="responses[indexed(cind, gind)] ? responses[indexed(cind, gind)] : null"
                              mode="assign"/>
            </b-col>
          </b-row>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import WordCardTeaser from "../../teasers/WordCardTeaser"
import {shuffleArray} from "../../../helpers/shuffleArray"

export default {
  props: {
    cards: {},
    groupSize: {},
    groupIndex: {}
  },
  name: "WordCardsMatchingExercise",
  components: {
    WordCardTeaser
  },
  data() {
    return {
      selectedWord: null,
      shuffled: [],
      responses: [],
      groups: [],
      shuffledGroups: []
    }
  },
  computed: {
    allResponded() {
      return this.responses.filter(x => x && x.word).length === this.cards.length
    },
    groupResponded() {
      return this.selectedGroupIndexes.every(r => this.respondedIndexes.includes(r))
    },
    selectedGroupIndexes() {
      const arr = []
      for(var index = 0; index < this.groupSize; index++) {
        arr.push(this.indexed(index, this.groupIndex))
      }
      return arr
    },
    respondedIndexes() {
      const arr = []
      for(var index = 0; index < this.responses.length; index++) {
        if(this.responses[index] && this.responses[index].word) arr.push(index)
      }
      return arr
    }
  },
  methods: {
    selectWord(word) {
      if(this.cardUsed(word)) return
      if(this.selectedWord && this.selectedWord.word === word.word) {
        this.selectedWord = null
        return
      }
      this.selectedWord = word
    },
    dropWord(index) {
      if(!this.selectedWord && this.responses[index] && this.responses[index].word) {
        this.$set(this.responses, index, null)
        return
      }
      if(!this.selectedWord) return
      this.$set(this.responses, index, this.selectedWord)
      this.selectedWord = null
    },
    cardUsed(card) {
      return this.responses.filter(x => x && x.word === card.word).length
    },
    indexed(i, i2) {
      return i + i2 * this.groupSize
    }
  },
  mounted() {
    this.shuffled = shuffleArray(JSON.parse(JSON.stringify(this.cards)))
    for (var i = 0; i < this.shuffled.length; i += this.groupSize) {
      this.groups.push(shuffleArray(this.shuffled.slice(i, i + this.groupSize)))
      this.shuffledGroups.push(shuffleArray(this.shuffled.slice(i, i + this.groupSize)))
    }
  }
}
</script>

<style scoped lang="scss">
.words-list {
  background: #F8F9FB;
  border-radius: 16px;
  padding: 20px 20px;
  margin-bottom: 20px;
  margin-top: 30px;
  .drag-block {
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(105, 114, 121, 0.25);
    border-radius: 10px;
    margin: 2px 10px 2px 0;
    display: inline-block;
    border: 1px solid transparent;
    padding: 9px 12px;
    &.active {
      border: 1px solid $text-gray3;
      box-sizing: content-box;
      box-shadow: none;
    }
    &.used {
      background: #EEF0F3;
      color: #B8BDCA;
    }
  }
}
</style>
