<template>
  <div class="checkbox form-group">
    <input type="checkbox" 
           :name="fid" 
           value="1" 
           :checked="value" 
           :id="fid" 
           @input="handleInput"/>
    <label class="mb-0" v-if="label" :for="fid">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'CheckboxGroup',
  data() {
    return {
      content: this.value
    }
  },
  props: {
    label: {},
    value: {},
    fid: {},
  },
  mounted() {
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e.target.checked)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
