<template>
  <div class="word-teaser">
    <div class="word-teaser-content">
      <div class="b-bottom">
        <b-row class="align-items-center">
          <b-col cols="auto" v-if="audio">
            <a @click="playAudio" class="pointer">
              <AudioSvg/>
            </a>
          </b-col>
          <b-col>
            <div class="word">{{ word.word }}</div>
            <div class="translation">{{ word.translation_ru ? word.translation_ru : word.translation }}</div>
          </b-col>
          <b-col cols="auto">
            <a v-if="candelete" class="pointer" @click="del">
              <CloseSvg class="svg-icon-md"/>
            </a>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import CloseSvg from '@/assets/svg/close2.svg'
import AudioSvg from '@/assets/svg/audio.svg'
import {generateAudio} from "../../helpers/generateAudio"

export default {
  components: {
    CloseSvg,
    AudioSvg
  },
  data() {
    return {

    }
  },
  props: {
    word: { type: Object },
    candelete: { type: Boolean, default: false },
    audio: { type: Boolean, default: true },
    canedit: { type: Boolean, default: false }
  },
  methods: {
    del() {
      this.$emit('delete')
    },
    async playAudio() {
      if(!this.word.audio_url) {
        let audioUrl = await generateAudio(this.word.word, this.word.id)
        if (audioUrl) this.word.audio_url = audioUrl
        return
      }
      const audio = new Audio(this.word.audio_url)
      await audio.play()
    }
  },
}
</script>

<style scoped lang="scss">
.word-teaser {
  &-content {
    padding: 12px 16px 0 16px;
    border-radius: 16px;
    .b-bottom {
      padding-bottom: 12px;
      border-bottom: 1px solid $border-gray;
      .word {
        font-size: 15px;
        font-weight: 500;
      }
      .translation {
        font-size: 13px;
        font-weight: 400;
        color: $text-gray3;
      }
    }
    &:hover {
      background: $almost-white;
    }
  }
}


@media(max-width: 768px) {
  .word-teaser {
    &-content {
      padding: 8px 8px 0 8px;
      border-radius: 14px;
    }
  }
}

</style>






