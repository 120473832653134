<template>
  <b-modal id="subrooms-modal"
           @show="onShow"
           size="lg"
           modal-class="rounded">
    <template v-slot:modal-header="{ close }">
      <div class="w-100">
        <b-row class="align-items-center mb-md-3 mb-2">
          <b-col>
            <h2 class="mb-0">{{ $t('session_halls') }}</h2>
          </b-col>
          <b-col cols="auto">
            <a class="call_room_btn" @click="close()">
              <CloseSvg/>
            </a>
          </b-col>
        </b-row>
      </div>
    </template>
    <hr class="mt-0"/>
    <div v-if="timer" class="alert alert-warning">
      {{ $t('students_will_be_returned_after') }} {{ fancyTimeFormat(secondsLeft) }}
      <a @click="cancelTimer" class="text-underlined ml-md-3 ml-2 text-lowercase pointer">{{ $t('cancel') }}</a>
    </div>
    <div class="mob-limited-h">
      <b-row class="no-gutters">
        <b-col md cols="12"
               class="pr-md-4 pr-0 mb-md-0 mb-3">
          <div class="room-wrap room-main"
               :class="{ visited: studentsBySubrooms[0].length }">
            <label class="label room-header"
                   @click="roomClicked(0)">
              {{ $t('common_room') }}
            </label>
            <draggable :list="studentsBySubrooms[0]"
                       @end="dragged"
                       class="room-drag"
                       group="people">
              <div v-for="(student, sind) in studentsBySubrooms[0]"
                   :key="`subrrom-0-${sind}`"
                   v-if="student.id != hostId"
                   @click="selectUser(student)"
                   :class="{ selected: selectedUser && student.id == selectedUser.id }"
                   class="student">
                <b-row class="align-items-center">
                  <b-col cols="auto">
                    <LetterIcon :obj="student"
                                :title="student.name"
                                class="student-icon xsm"/>
                  </b-col>
                  <b-col>
                    {{ student.name }}
                  </b-col>
                </b-row>
              </div>
              <div class="drag-n-drop d-block" v-if="!studentsBySubrooms[0] || !studentsBySubrooms[0].length">{{ $t('empty') }}</div>
            </draggable>
          </div>
        </b-col>
        <b-col md cols="12">
          <div class="mb-4"
               v-for="subroom in numOfSubrooms"
               :key="`subroom-${subroom}-${counter}`" >
            <div  class="room-wrap">
              <label class="label room-header"
                     :class="{ hasHost: hasHost(subroom) }"
                     @click="roomClicked(subroom)">
                {{ $t('room')+' #'+subroom }}
                <PlusSvg/>
              </label>
              <draggable :list="studentsBySubrooms[subroom]"
                         @end="dragged"
                         class="subroom room-drag"
                         :key="`subroom-dr-${subroom}`"
                         group="people">
                <div v-for="(student, sind) in studentsBySubrooms[subroom]"
                     :key="`subrrom-${subroom}-${sind}`"
                     v-if="student.id != hostId"
                     @click="selectUser(student)"
                     :class="{ selected: selectedUser && student.id == selectedUser.id }"
                     class="student">
                  <b-row class="align-items-center">
                    <b-col cols="auto">
                      <LetterIcon :obj="student"
                                  :title="student.name"
                                  class="student-icon xsm"/>
                    </b-col>
                    <b-col>
                      {{ student.name }}
                    </b-col>
                  </b-row>
                </div>
                <div v-if="!studentsBySubrooms[subroom] || !studentsBySubrooms[subroom].length"
                     class="drag-n-drop"
                     @click="roomClicked(subroom)">
                  Drag & drop student here
                </div>
              </draggable>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <hr/>
    <template v-slot:modal-footer>
      <div class="w-100 mx-0">
        <b-row>
          <b-col md cols="12" class="mb-md-0 mb-2">
            <b-button @click="allocateStudents"
                      class="btn-themed btn-white w-mob-100">
              {{ $t('allocate') }}
            </b-button>
          </b-col>
          <b-col md="auto" cols="12">
            <b-dropdown ref="timerdd"
                        dropup
                        no-caret
                        menu-class="noscrollbar"
                        variant="link"
                        class="mb-md-0 mb-2 d-md-inline-block d-block pointer"
                        toggle-class="w-mob-100">
              <template v-slot:button-content>
                <a class="btn-themed btn-white w-mob-100">
                  <TimerSvg/>
                  {{ $t('timer') }}
                </a>
              </template>
              <h2 class="timer-header">{{ $t('timer') }}</h2>
              <hr class="mt-0"/>
              <div class="timer-form">
                <div class="text-center">
                  <div class="text-center d-inline-block mx-auto">
                    <b-row>
                      <b-col>
                        <input type="number" v-model="minutes"/>
                        <div class="subtitle">{{ $t('minutes') }}</div>
                      </b-col>
                      <b-col>:</b-col>
                      <b-col>
                        <input type="number" v-model="seconds"/>
                        <div class="subtitle">{{ $t('seconds') }}</div>
                      </b-col>
                    </b-row>
                  </div>
                  <b-row class="mt-3">
                    <b-col v-for="pt in preTimes" :key="`pret-${pt}`" cols="6">
                      <span @click="pretimePressed(pt)" class="pre-time">{{ pt }} {{ $t('min') }}</span>
                    </b-col>
                  </b-row>
                  <b-button class="btn-themed w-100 mt-4 nowrap" @click="launchTimer">{{ $t('launch_timer') }}</b-button>
                </div>
              </div>
            </b-dropdown>
            <b-button @click="dropAll"
                      class="btn-themed btn-danger ml-md-2 w-mob-100">
              {{ $t('return_all') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>

</template>

<script>

import CloseSvg from '@/assets/svg/close_gray.svg'
import TimerSvg from '@/assets/svg/timer.svg'
import PlusSvg from '@/assets/svg/plus.svg'
import draggable from 'vuedraggable'
import {mapState} from "vuex"
import LetterIcon from '@/views/parts/general/LetterIcon'
import { fancyTimeFormat } from "../../../helpers/fancyTimeFormat"

export default {
  name: "Subrooms",
  components: {
    draggable, // draggable does not seem to work in modals
    CloseSvg,
    TimerSvg,
    PlusSvg,
    LetterIcon
  },
  props: {
    passedStudents: {},
    usersdata: {},
  },
  data() {
    return {
      numOfSubrooms: 5,
      studentsBySubrooms: {},
      timer: null,
      minutes: 0,
      seconds: 30,
      secondsLeft: 0,
      preTimes: [5, 10, 15, 20, 30, 60],
      counter: 0,
      selectedUser: null,
    }
  },
  computed: {
    ...mapState({
      stateStudentsBySubrooms: state => state.call.studentsBySubrooms,
      roomsSecondsLeft: state => state.call.roomsSecondsLeft,
    }),
    students() {
      if(!this.passedStudents) return {}
      // console.log(this.passedStudents)
      // return this.passedStudents
      let obj = {}
      for (var key in this.usersdata) {
        if ({}.hasOwnProperty.call(this.usersdata, key)) {
          let userId = this.usersdata[key].dbUserId
          if(this.passedStudents[userId]) {
            obj[userId] = this.passedStudents[userId]
          }
        }
      }
      if(this.passedStudents[this.hostId]) {
        obj[this.hostId] = this.passedStudents[this.hostId]
      }
      return obj
      // return this.passedStudents.filter(s => arr.contains(s.id))
    },
    hostId() {
      return this.$store.state.user.id
    },
    timerSeconds() {
      return parseInt(this.minutes * 60) + parseInt(this.seconds)
    }
  },
  mounted() {
  },
  methods: {
    onShow() {
      this.initiateList()
      this.findWithoutRooms()
    },
    allocateStudents() {
      let totalNum = Object.values(this.students).length - 1
      let upByRoom = Math.ceil(totalNum / this.numOfSubrooms)
      let remainder = upByRoom * this.numOfSubrooms - totalNum
      let roomAllocation = [];
      for(var i = 0; i <= this.numOfSubrooms; i++) {
        this.$set(this.studentsBySubrooms, i, [])
        if(i > 0) roomAllocation.push(upByRoom - (i > (this.numOfSubrooms - remainder) ? 1 : 0))
      }
      for(const studentId in this.students) {
        console.log(studentId)
        if(studentId == this.hostId) {
          this.studentsBySubrooms[0].push(this.students[studentId])
          continue;
        }
        for(var x = 1; x < (this.numOfSubrooms + 1); x++) {
          if(this.studentsBySubrooms[x].length < roomAllocation[(x - 1)]) {
            this.studentsBySubrooms[x].push(this.students[studentId])
            break;
          }
        }
      }
      console.log(this.studentsBySubrooms)
      // this.studentsBySubrooms = []
      this.dragged()
    },
    dropAll() {
      if(this.timer) {
        clearInterval(this.timer)
        this.timer = null
        this.$store.commit('setRoomsSecondsLeft', 0)
      }
      this.studentsBySubrooms = {}
      this.dragged()
      this.onShow()
    },
    initiateList() {
      for(var i = 0; i <= this.numOfSubrooms; i++) {
        this.$set(this.studentsBySubrooms, i, [])
      }
      for(const studentId in this.stateStudentsBySubrooms) {
        const room = this.stateStudentsBySubrooms[studentId]
        let student = this.students[studentId]
        if(student) this.studentsBySubrooms[room].push(student)
      }
    },
    findWithoutRooms() {

      if(!this.students) return []
      const exitingStudentIds = Object.keys(this.students)
      for(const studentId in this.students) {
        let found = false
        for(const roomId in this.studentsBySubrooms) {
          if(this.studentsBySubrooms[roomId].find(student => student.id == studentId)) found = true
          this.studentsBySubrooms[roomId] = this.studentsBySubrooms[roomId].filter(student => exitingStudentIds.includes(String(student.id)))
        }
        if(!found) {
          if(!this.studentsBySubrooms[0]) {
            this.$set(this.studentsBySubrooms, 0, [])
          }
          this.studentsBySubrooms[0].push(this.students[studentId])
        }
      }
    },
    findUserInRoom(userId) {
      for(const roomId in this.studentsBySubrooms) {
        let r = this.studentsBySubrooms[roomId].find(x => x.id == userId)
        if(r) return roomId
      }
      return null
    },
    hasHost(room) {
      if(!this.studentsBySubrooms[room]) return false
      return !!this.studentsBySubrooms[room].find(x => x.id == this.hostId)
    },
    selectUser(user) {
      if(this.selectedUser && this.selectedUser.id == user.id) {
        this.selectedUser = null
        return
      }
      this.selectedUser = user
    },
    dragged() {
      const userToRoom = {}
      for(const roomId in this.studentsBySubrooms) {
        for(const student of this.studentsBySubrooms[roomId]) {
          userToRoom[student.id] = roomId
        }
      }
      this.selectedUser = null
      this.$store.commit('setStudentsBySubrooms', userToRoom)
    },
    moveUserToRoom(userId, destinationRoom) {
      if(!userId) return
      let currentRoom = this.findUserInRoom(userId)
      if(!currentRoom || currentRoom == destinationRoom) return
      let user = this.studentsBySubrooms[currentRoom].find(x => x.id == userId)
      this.studentsBySubrooms[currentRoom] = this.studentsBySubrooms[currentRoom].filter(x => x.id != userId)
      this.studentsBySubrooms[destinationRoom].push(user)
      this.$nextTick(() => {
        this.dragged()
        this.counter++
        if(userId === this.hostId) {
          this.$bvModal.hide('subrooms-modal')
        }
      })
    },
    roomClicked(subroom) {
      if(this.selectedUser) {
        this.moveUserToRoom(this.selectedUser.id, subroom)
        return
      }
      this.moveUserToRoom(this.hostId, subroom)
    },
    launchTimer(setTime = true) {
      if(setTime) this.secondsLeft = this.timerSeconds
      if(this.timer) clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.secondsLeft--
        this.$store.commit('setRoomsSecondsLeft', this.secondsLeft)
        if(this.secondsLeft <= 0) {
          this.dropAll()
        }
      }, 1000)
      if(this.$refs.timerdd) this.$refs.timerdd.hide()
    },
    cancelTimer() {
      this.secondsLeft = 0
      this.dropAll()
    },
    fancyTimeFormat(duration) {
      return fancyTimeFormat(duration)
    },
    pretimePressed(minutes) {
      this.minutes = minutes
      this.seconds = 0
    }
  },
  watch: {
    students: {
      handler() {
        this.findWithoutRooms()
      },
      deep: true,
      immediate: true
    },
    roomsSecondsLeft(val) {
      if(val && val > 0 && val != this.secondsLeft && !this.timer) {
        this.secondsLeft = val
        this.launchTimer(false)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.drag-n-drop {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 4px 14px;
  color: #C7CACE;
  font-size: 12px;
  display: none;
  background: #f9f9fb;
  &:only-child {
    display: block;
  }
}
.student {
  cursor: move;
  padding: 5px 5px 5px 14px;
  font-size: 13px;
  &.selected {
    background: $gray-back4;
  }
  &:hover {
    background: $gray-back4;
  }
}
.timer-header {
  font-weight: bold;
  font-size: 14px;
  padding-top: 12px;
  padding-left: 16px;
  margin-bottom: 12px;
}
.timer-form {
  padding: 0 16px 6px 16px;
  min-width: 200px;
  input {
    border: 1px solid #E6EAF2;
    border-radius: 7px;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    width: 50px;
    height: 42px;
    text-align: center;
  }
  .pre-time {
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    border: 1px solid #E6EAF2;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 4px 8px;
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
  }
  .subtitle {
    color: #7E828C;
  }
}
.room-wrap {
  border-radius: 10px;
  overflow: hidden;
  border: 1px dashed #E6EAF2;
  .room-header {
    display: block;
    font-size: 13px;
    line-height: 16px;
    padding: 4px 14px;
    margin-bottom: 0;
    font-weight: 700;
    position: relative;
    &.hasHost {
      background: #C7E9F9;
      color: $text-themed;
      svg {
        fill: $text-themed;
      }
    }
    svg {
      fill: #000;
      width: 10px;
      height: 10px;
      position: absolute;
      right: 12px;
      top: 7px;
    }
  }
  .room-drag {
    padding: 5px 0;
    background: #F9F9FB;
  }
  &.room-main.visited {
    border: 1px solid $text-themed;
    box-shadow: 0px 1px 8px rgba(67, 180, 235, 0.25);
    .room-header {
      background: $text-themed;
      color: #fff;
    }
  }
}

@media(max-width: 768px) {
  .mob-limited-h {
    height: calc(100vh - 269px);
    overflow: scroll;
  }
  h2 {
    font-size: 16px;
  }
}
</style>
