<template>
  <vue-resizable :left="isMobile ? 0 : 72"
                 :width="isMobile ? windowW : 300"
                 :disableAttributes="[]"
                 :minWidth="300"
                 :minHeight="200"
                 :height="isMobile ? (windowH - 64) : 450"
                 @resize:start="resizeStart"
                 @resize:end="resizeEnd"
                 :active="['r','b', 'l', 't', 'lb', 'rb', 'lt', 'rt']"
                 dragSelector=".drag-part">
<!--    <vue-draggable-resizable>-->
    <div class="spacecard h-100">
      <div class="chat-wrapper">
        <div class="drag-part">
          <b-row class="align-items-center">
            <b-col>
              <h3 class="mb-0">{{ mode === 'chat' ? $t('chat') : $t('vocabulary') }}</h3>
            </b-col>
            <b-col cols="auto">
              <a class="pointer position-relative modal-close call_room_btn"
                 @click="mode === 'chat' ? $parent.chatShow = false : $parent.vocShow = false">
                <CloseSvg/>
              </a>
            </b-col>
          </b-row>
        </div>
        <hr/>
        <div class="d-flex flex-column justify-content-between height-calc">

          <div class="chat-card h-100 d-flex flex-column justify-content-between overflow-hidden">
            <div class="messages-wrapper noscrollbar h-100"
                 ref="messagewrapper"
                 v-if="mode == 'chat'"
                 @scroll="handleScroll">
              <div v-for="(message, mind) in uniqueMessages"
                   :key="'message-'+mind"
                   :ref="'message-'+message.id"
                   class="message"
                   :class="{
                            mine: user && message.author_id == user.id,
                            repeat: mind > 0 && messages[(mind - 1)].author_id == message.author_id
                         }">
                <b-row class="align-items-end no-gutters">
                  <b-col cols="auto">
                    <div class="user-icon-wrap">
                      <LetterIcon v-if="!user || message.author_id != user.id"
                                  :obj="students[message.author_id]"
                                  :title="message.author_name ? message.author_name : (students[message.author_id] ? students[message.author_id].name : 'User')"
                                  class="student-icon icon-chat"/>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="message__content position-relative">
                      <div class="message__username" v-if="!user || message.author_id != user.id">
                        {{ message.author_name ? message.author_name : (students[message.author_id] ? students[message.author_id].name : 'User') }}
                      </div>
                      <div>
                        <div class="message__message">{{ message.message }}</div>
                        <div class="message__time">{{ message.local_date | moment('HH:mm') }}</div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="pt-2 p-1" v-if="mode == 'chat' && !homework">
              <div class="position-relative">
                <AutosizeTextarea class="message-textarea"
                                  @enter="enterPressed"
                                  :standardEnter="false"
                                  :placeholder="$t('message')"
                                  v-model="messageText"/>
                <button @click="send" class="send-message">
                  <SendSvg/>
                </button>
              </div>
            </div>
            <div class="messages-wrapper noscrollbar h-100" v-if="mode == 'words'">
              <div ref="addword">
                <AddWord @selected="addWord"/>
              </div>
              <WordTeaser v-for="w in words"
                          :key="'word-'+w.id"
                          :word="w">
              </WordTeaser>
            </div>
          </div>
        </div>
      </div>
    </div>
  </vue-resizable>
</template>

<script>
import SendSvg from "@/assets/svg/send.svg"
import AutosizeTextarea from "@/views/parts/general/form/AutosizeTextArea"
import WordTeaser from '@/views/teasers/WordTeaser'
import { ChatService } from '@/services/api.service'
import LetterIcon from '@/views/parts/general/LetterIcon'
import AddWord from "../../parts/class/AddWord";
import {VocabularyService} from "../../../services/api.service";
import { mapState } from 'vuex'
import VueResizable from 'vue-resizable'
import CloseSvg from '@/assets/svg/close_gray.svg'
import EventBus from '@/services/event-bus'
import moment from "moment-timezone"
import { uuid } from 'vue-uuid'

export default {
  components: {
    SendSvg,
    AutosizeTextarea,
    WordTeaser,
    LetterIcon,
    CloseSvg,
    AddWord,
    VueResizable
  },
  data() {
    return {
      messageText: "",
      watchMess: true,
      currentRef: null,
      newmessage: false,
      messages: [],
      no_more_messages: false,
    }
  },
  props: {
    students: { type: Object },
    socket: {},
    mode: {},
  },
  methods: {
    addWord(word) {
      let params = {
        word: word.word,
        translation: word.translation_ru
      };
      if (!this.host) {
        VocabularyService.addWordToStudentStudy(params).then(() => {
          this.$toast(this, this.$t('add_word'))
        })
      } else {
        VocabularyService.addWordToTeacherStudy(this.lessonId, params).then(() => {
          this.$toast(this, this.$t('add_word'))
        })
      }
    },
    resizeStart() {
      this.$store.commit('setShowIframe', false)
    },
    resizeEnd() {
      this.$store.commit('setShowIframe', true)
    },
    send() {
      if (!this.messageText || !this.myPeerId) return
      if(this.user) ChatService.sendMessage(this.room, this.messageText)
      let message = {
        id: uuid.v4(),
        created_at: moment().format('Y-m-d H:i:s'),
        message: this.messageText,
        author_id: this.user ? this.user.id : Math.random(),
        author_name: this.user ? this.user.name : 'User',
        type: "user"
      }
      if(this.socket) this.socket.emit('message', this.room, message)
      this.messages.push(message)

      this.messageText = ""
      this.scrollBottom()
    },
    handleScroll: function(el) {
      if(el.target.scrollTop < 1) { // scrolled to top
        this.$emit('loadmoremessages')
      }
    },
    scrollBottom() {
      setTimeout(() => {
        this.$refs.messagewrapper.scrollTop = this.$refs.messagewrapper.scrollHeight
      }, 50)
      setTimeout(() => {
        this.newmessage = false
        this.$store.commit('setNewMessage', false)
      }, 1500)
    },
    enterPressed() {
      this.send()
    },
    messageNotification() {
      this.newmessage = true
      if(this.mode === 'chat') {
        this.scrollBottom()
      }
    },
    getMessages() {
      if(!this.room) return
      ChatService.getMessages(this.room).then(res => {
        this.messages = res.data.data
        if (res.data.data.length == 0) {
          this.no_more_messages = true
        }
      })
    },
  },
  mounted() {
    if(this.user) this.getMessages()
    EventBus.$on('newChatMessage', (message) => {
      this.messages.push(message)
      this.$store.commit('setNewMessage', true)
      this.messageNotification()
    })

  },
  watch: {
    messages: {
      deep: true,
      handler() {
        if(this.watchMess) {
          if(this.messages.length > 0) {
            this.currentRef = 'message-'+this.messages[0].id
          }
          if(this.mode === 'chat') {
            this.scrollBottom()
          }
        }
      }
    },
    mode(val) {
      if(val === 'chat') {
        this.scrollBottom()
      }
    }
  },
  computed: {
    ...mapState({
      host: state => state.call.host,
      room: state => state.call.room,
      myPeerId: state => state.call.myPeerId,
      homework: state => state.call.isHomework,
      lessonId: state => state.call.lessonId,
      words: state => state.call.lessonWords,
      user: state => state.user,
      isMobile: state => state.isMobile,
    }),
    windowW() {
      return window.innerWidth
    },
    windowH() {
      return window.innerHeight
    },
    uniqueMessages() {
      return this.messages.filter((message, index, self) =>
        index === self.findIndex((t) => (
          t.id === message.id
        ))
      )
    }
  },
}
</script>

<style scoped lang="scss">
.chat-wrapper {
  height: 100%;
  .height-calc {
    height: calc(100% - 67px);
  }
  .chat-card {
    .chat-header {
      margin-bottom: 24px;
    }
    .messages-wrapper {
      overflow: scroll;
      .message {
        &:first-of-type {
          margin-top: 0;
        }
        margin-top: 16px;
        &.repeat {
          margin-top: 6px;
          .message__username {
            display: none;
          }
          .student-icon {
            display: none;
          }
        }
        &__content {
          border-radius: 12px;
          border-bottom-left-radius: 0;
          border: 1px solid $border-gray;
          background: #fff;
          padding: 7px 25px 7px 9px;
          font-weight: 400;
          font-size: 14px;
          max-width: 80%;
          white-space: pre-wrap;
          margin-left: 8px;

        }
        .user-icon-wrap {
          width: 36px;
        }
        .student-icon {
          width: 36px;
          height: 36px;
        }
        &__time {
          position: absolute;
          font-size: 10px;
          color: $text-gray3;
          right: 10px;
          bottom: 5px;
        }
        &__username {
          font-size: 12px;
          font-weight: 600;
          color: $text-gray3;
        }
        &__message {
          font-weight: 500;
          word-break: break-word;
        }
        &.mine {
          .message__content {
            background: $gray-back2;
            border: 1px solid $gray-back2;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 0;
            margin-left: 20%;
            .message__time {
              text-align: right;
            }
          }
        }
      }
    }
    .send-message {
      right: 0;
      top: 0;
      position: absolute;
      height: 100%;
      border: none;
      background: transparent;
      outline: none;
      svg {
        fill: $gray-fill;
      }
    }
    .message-textarea {
      width: calc(100%  - 45px);
      font-size: 15px;
      &:focus {
        background: $gray-back;
      }
    }
  }
  .newmessage {
    position: absolute;
    display: inline-block;
    margin-left: -30px;
    left: 50%;
    width: 9px;
    height: 9px;
    bottom: 22px;
    border-radius: 100%;
    background: $theme-red;
  }
}

@media(max-width: 768px) {
  .spacecard {
    border-radius: 0;
    border: none;
  }
}
</style>






