<template>
  <div class="word-cards bg-white text-center"
       :class="[{
           'pb-5': view === 'student',
           half: lessonModesEnabled.length === 1 && !lessonModeFull,
           halfer: lessonModesEnabled.length === 2,
           toRight: lessonModesEnabled.length === 2 && lessonModesEnabled.indexOf('cards') == 1,
           withoutVideo: view === 'selfstudy' || homework,
  }, 'view-'+view]">
    <div class="lesson-mode-header w-100">
        <b-row class="align-items-center w-100">
          <b-col class="text-left">
            <h2 class="mb-0" v-if="view === 'lesson'">Тренировка слов</h2>
          </b-col>
          <b-col cols="auto">
            <button class="call_room_btn"
                    v-if="view !== 'student'"
                    @click="$store.commit('expandLessonMode', 'cards')">
              <ExpandSvg v-if="!lessonModeFull"/><CollapseSvg v-if="lessonModeFull"/>
            </button>
            <button class="call_room_btn ml-2" @click="close">
              <CloseSvg/>
            </button>
          </b-col>
        </b-row>
    </div>

    <div class="pt-4">
      <div v-if="!finished">
        <div class="step-decorator">{{ $t('step_x_out_of', { step: step, steps: stepTitles.length }) }}</div>
        <div class="step-title">{{ $t(stepTitles[(step - 1)]) }}</div>
        <div v-if="['memorize', 'letters', 'choose'].includes(mode)">
          <div class="words-dots">
            <span v-for="(c, cind) in cards"
                  :key="`dot-${cind}`"
                  :class="{ active: cind === cardIndex}" class="dot">
            </span>
          </div>
          <WordCardTeaser :word="card"
                          :otherCards="cards"
                          :mode="mode"/>
        </div>
        <div v-if="['assign'].includes(mode)">
          <div class="words-dots">
            <span v-for="groupIndex in groups"
                  :key="`dot-${groupIndex}`"
                  :class="{ active: (groupIndex - 1) === cardIndex}" class="dot">
            </span>
          </div>
        </div>
        <WordCardsMatchingExercise v-if="['assign'].includes(mode)"
                                   :groupSize="groupSize"
                                   :groupIndex="cardIndex"
                                   :cards="cards"/>
        <div class="words-navigation text-center">
          <button class="btn-themed btn-white mx-2"
                  @click="back"
                  :disabled="cardIndex <= 0 && step <= 1">
            <LeftSvg class="mr-2"/>
            {{ $t('back') }}
          </button>
          <button class="btn-themed mx-2"
                  @click="next"
                  :disabled="step >= (stepTitles.length + 1)">
            {{ $t('next') }}
            <RightSvg class="ml-2"/>
          </button>
        </div>
      </div>
      <div v-if="finished">
        <FinishSvg/>
        <h2 class="mt-3 mb-1">Тренировка завершена</h2>
        <div class="text-muted">Выучено новых слов:</div>
        <div class="count-words">{{ cards.length }}</div>
        <div>
          <button class="btn-themed" @click="done">{{ $t('done') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import WordCardTeaser from "../../teasers/WordCardTeaser"
import FinishSvg from "@/assets/svg/finish.svg"
import RightSvg from "@/assets/svg/arrow_right.svg"
import LeftSvg from "@/assets/svg/arrow_left.svg"
import CloseSvg from "@/assets/svg/close_gray.svg"
import ExpandSvg from "@/assets/svg/expand.svg"
import CollapseSvg from "@/assets/svg/collapse.svg"
import WordCardsMatchingExercise from "./WordCardsMatchingExercise"
import {mapState} from "vuex"

export default {
  name: "WordCardsLessonPart",
  components: {
    WordCardsMatchingExercise,
    WordCardTeaser,
    RightSvg,
    LeftSvg,
    FinishSvg,
    CloseSvg,
    ExpandSvg,
    CollapseSvg
  },
  props: {
    cards: {},
    view: {},
  },
  data() {
    return {
      cardIndex: 0,
      step: 1,
      groupSize: 9,
      stepTitles: [
        'look_and_remember',
        // 'collect_word_from_letters',
        'choose_right_variant',
        'assign_words_to_cards'
      ],
      finished: false
    }
  },
  computed: {
    ...mapState({
      // host: state => state.call.host,
      // linkchatUrl: state => state.call.linkchatUrl,
      homework: state => state.call.isHomework,
      // material: state => state.call.material,
      lessonModesEnabled: state => state.call.lessonModesEnabled,
      lessonModeFull: state => state.call.lessonModeFull,
    }),
    card() {
      return this.cards[this.cardIndex] ?? null
    },
    mode() {
      // let arr = ['memorize', 'letters', 'choose', 'assign']
      let arr = ['memorize', 'choose', 'assign']
      return arr[(this.step - 1)]
    },
    groups() {
      return Math.ceil(this.cards.length / this.groupSize)
    }
  },
  methods: {
    back() {
      if(this.cardIndex > 0) {
        this.cardIndex--
        return
      }
      if(this.cardIndex <= 0) {
        if(this.step > 1) {
          this.step--
          this.cardIndex = this.cards.length - 1
        }
      }
    },
    next() {
      if(this.step == this.stepTitles.length && (this.cardIndex + 1) < this.groups) {
        this.cardIndex++
        return
      }
      if(this.step == this.stepTitles.length) {
        this.finished = true
        return
      }
      if((this.cardIndex + 1) < this.cards.length) {
        this.cardIndex++
        return
      }
      if((this.cardIndex + 1) >= this.cards.length) {
        if(this.step < this.stepTitles.length) {
          this.step++
          this.cardIndex = 0
        }
      }
    },
    done() {
      this.$emit('done')
    },
    close() {
      this.$store.commit('toggleLessonMode', 'cards')
      if(this.$route.params.homework && this.$route.params.homework === 'homework') {
        this.$parent.slidemodechange('homework')
      } else {
        this.$parent.slidemodechange('lesson')
      }
    }
  }
}
</script>

<style scoped lang="scss">
h1 {
  font-weight: bold;
  font-size: 20px;
}
.step-title {
  font-weight: bold;
  font-size: 20px;
  color: #7E828C;
}
.word-cards {
  .lesson-mode-header {
    position: sticky;
    top: 0;
    background: white;
    z-index: 6;
  }
  position: fixed;
  left: 72px;
  top: 0;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #fff;
  z-index: 19;
  width: calc(100vw - 72px - 145px);
  .step-decorator {
    background-color: #ECB8FF;
    color: #fff;
    border-radius: 7px;
    padding: 6px 10px;
    font-size: 12px;
    font-weight: bold;
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  &.withoutVideo {
    width: calc(100vw - 72px);
  }
  &.half {
    width: calc(50vw - 36px);
  }
  &.halfer {
    width: calc(50vw - 36px - 72.5px);
    &.withoutVideo {
      width: calc(50vw - 36px);
    }
  }
  &.toRight {
    left: auto;
    right: 145px;
    border-left: 1px solid #E8EFFA;
    &.withoutVideo {
      right: 0;
    }
  }
  &.view-student {
    left: 0;
    width: 100vw;
  }
}
.words-dots {
  margin-top: 26px;
  margin-bottom: 30px;
  text-align: center;
  span {
    width: 4px;
    height: 4px;
    background: #C5CDDC;
    border-radius: 100%;
    margin: 0 3px;
    display: inline-block;
    vertical-align: middle;
    transition: ease-in 0.2s;
    &.active {
      transform: scale(1.8);
    }
  }
}
.count-words {
  font-size: 36px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 30px;
}
h2 {
  font-size: 22px;
}
.words-navigation {
  margin-top: 24px;
}

@media(max-width: 768px) {
  .words-dots {
    margin-top: 12px;
    margin-bottom: 20px;
  }
  .step-title {
    font-size: 18px;
  }
  h2 {
    font-size: 18px;
  }
  .word-cards {
    width: 100vw;
    left: 0;
    bottom: 64px;
    top: auto;
    height: calc(var(--doc-height) - 64px);
    //overflow: hidden;
    &.inZoomMode {
      overflow: hidden;
    }
    &.withoutVideo, &.half, &.halfer {
      width: 100vw;
      bottom: 64px;
    }
    &.half {
      height: calc(var(--doc-height) * 0.65 - 32px);
    }
    &.withoutVideo {
      height: calc(var(--doc-height) - 64px);
    }
    &.halfer {
      height: calc(var(--doc-height) * 0.5 - 32px);
      &.withoutVideo {
        width: 100%;
      }
    }
    &.toRight {
      right: 0;
      top: 0;
    }
  }
}
</style>
