<template>
  <div class="accessory-row">
    <div class="content">
      <b-row class="align-items-center">
        <slot/>
        <b-col>
          <div v-if="subtitle" class="small text-muted">{{ subtitle }}</div>
          <div>
            {{ title }}
          </div>
        </b-col>
        <b-col cols="auto" v-if="editEnabled || addEnabled">
          <a class="edit-btn mr-3"
             v-b-tooltip.hover
             :title="addTitle ? addTitle : $t('add')"
             @click.stop="add"
             v-if="addEnabled">
            <PlusSvg class="svg-icon-sm svg-darkgray"/>
          </a>
          <a class="edit-btn mr-2"
             @click.stop="edit"
             v-b-tooltip.hover
             :title="editTitle ? editTitle : $t('edit')"
             v-if="editEnabled">
            <EditSvg class="svg-icon-sm svg-darkgray"/>
          </a>
        </b-col>
        <b-col cols="auto">
          <RightSvg class="arrow"/>
        </b-col>
      </b-row>
    </div>
    <div class="ac-border"></div>
  </div>
</template>

<script>
import RightSvg from '@/assets/svg/right.svg'
import EditSvg from '@/assets/svg/pen.svg'
import PlusSvg from '@/assets/svg/plus.svg'

export default {
  components: {
    RightSvg,
    EditSvg,
    PlusSvg
  },
  props: {
    title: {},
    addTitle: {},
    editTitle: {},
    subtitle: {
      type: String,
      default: ''
    },
    editEnabled: {
      type: Boolean,
      default: false
    },
    addEnabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    edit() {
      this.$emit('edit')
    },
    add() {
      this.$emit('add')
    }
  }
}
</script>





