<template>
  <b-modal id="ratestudentmodal"
           @show="onShow"
           size="lg"
           modal-class="modal-right-pane">
    <template v-slot:modal-header="{ close }">
      <h2>{{ $t('rate_students') }}</h2>
      <a class="pointer position-relative modal-close" @click="close()">
        <CloseSvg/>
      </a>
    </template>
    <div class="mt-4 evaluation-block">
      <div v-for="(f, sid) in form" :key="'student-'+sid">
        <b-row class="align-items-center mb-5">
          <b-col md="auto" cols="auto">
            <LetterIcon :obj="f.student"
                        :title="f.student.name"
                        class="student-icon md"/>
            <!--                        <img class="student-icon" :src="f.student.image"/>-->
          </b-col>
          <b-col md="4" cols="8">
            <div class="">{{ f.student.name }}</div>
          </b-col>
          <b-col class="mt-2 mt-md-0">
            <div class="checkbox pr-2">
              <input type="checkbox"
                     v-model="f.attended"
                     @change="recountvalid"
                     :id="'st-'+f.student.id"/>
              <label class="mb-0" :for="'st-'+f.student.id">{{ $t('attended') }}</label>
            </div>
          </b-col>
          <b-col cols="auto">
            <star-rating :show-rating="false"
                         v-if="f.attended"
                         :padding="8"
                         @rating-selected="recountvalid"
                         active-color="#FFD964"
                         :star-size="isMobile ? 20 : 35"
                         v-model="f.grade">
            </star-rating>
          </b-col>
        </b-row>
      </div>
    </div>

    <template v-slot:modal-footer>
      <b-button class="btn-themed" :disabled="!valid" @click="save">{{ $t('save') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import CloseSvg from '@/assets/svg/close.svg'
import StarRating from 'vue-star-rating'
import { LessonsService } from "@/services/api.service"
import LetterIcon from '@/views/parts/general/LetterIcon'
import {mapState} from "vuex"

export default {
  components: {
    CloseSvg, StarRating, LetterIcon
  },
  data() {
    return {
      form: [],
      valid: false
    }
  },
  props: {
    lesson: {},
    students: {}
  },
  watch: {

  },
  computed: {
    ...mapState({
      isMobile: state => state.isMobile,
    })
  },
  methods: {
    save() {
      LessonsService.sendAttendance(this.lesson.id, { form: this.form }).then(() => {
        this.$emit('graded')
        this.$bvModal.hide('ratestudentmodal')
      })
    },
    recountvalid() {
      this.valid = true
      this.form.forEach(item => {
        if(!item.grade && item.attended) {
          this.valid = false
        }
      })
    },
    onShow() {
      this.form = []
      const students = Object.values(this.students)
      students.forEach(item => {
        if(item.id != this.$store.state.user.id) {
          this.form.push({
            id: item.id,
            student: item,
            attended: true,
            grade: null
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>


