<template>
  <div class="course-icon" :class="'course-type-'+ctype">
    <b-row class="align-items-center">
      <b-col cols="auto">
        <LetterIcon :obj="course"
                    :title="course.title"
                    class="course-icon-circle"/>
      </b-col>
      <b-col>
        <div class="course-icon-content">
          <h4>{{ course.title }}</h4>
          <p>{{ author }}</p>
        </div>
      </b-col>
      <span></span>
    </b-row>

  </div>
</template>

<script>
import LetterIcon from '@/views/parts/general/LetterIcon'

export default {
  components: {
    LetterIcon
  },
  data() {
    return {

    }
  },
  props: {
    course: { type: Object, default: null },
    ctype: { type: String },
  },
  methods: {

  },
  watch: {

  },
  mounted() {

  },
  computed: {
    author() {
      if(this.course.type == 'standard') {
        return this.$t('standard')
      }
      if(this.course.school) {
        return this.course.school.name
      }
      if(this.course.instructor) {
        return this.course.instructor.name
      }
      return this.$t('mine')
    }
  }

}
</script>

<style scoped lang="scss">

</style>






