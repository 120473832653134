<template>
  <b-modal id="presentationpreviewmodal"
           ref="presentationpreviewmodal"
           size="lg"
           modal-class="modal-right-pane">
    <template v-slot:modal-header="{ close }">
      <h2>{{ material.title }}</h2>
      <a class="pointer position-relative modal-close" @click="close()">
        <CloseSvg/>
      </a>
    </template>
    <PresentationPreview :slides="currentSlides"
                         class="px-3"
                         :slide="slide[slidemode]"
                         :homework="homework"
                         v-if="loaded"
                         :material="material"
                         :host="host"
                         @slidemodechange="slidemodechange"
                         :slidemode="slidemode"/>
    <template v-slot:modal-footer>
      <div v-if="loaded" class="w-100">
        <PresentationPreviewNavigation @slidechanged="slidechanged"
                                       @slidemodechange="slidemodechange"
                                       @finishhomework="homeworkDone"
                                       :changeModes="!material.light_course_lesson_id"
                                       :slide="slide[slidemode]"
                                       :homework="homework"
                                       :slidemode="slidemode"
                                       :slides="currentSlides"/>
      </div>
      <div v-else></div>
    </template>
  </b-modal>
</template>

<script>
import PresentationPreview from '@/views/parts/class/preview/PresentationPreview'
import PresentationPreviewNavigation from '@/views/parts/class/preview/PresentationPreviewNavigation'
import { LessonsService, StudentPageService } from '@/services/api.service'
import CloseSvg from '@/assets/svg/close.svg'

export default {
  components: {
    PresentationPreview,
    PresentationPreviewNavigation,
    CloseSvg
  },
  data() {
    return {
      slides: {
        "lesson": [],
        "homework": []
      },
      slide: {
        "lesson": 0,
        "homework": 0
      },
      loaded: false,
      slidemode: 'lesson',
      key: 2,
    }
  },
  props: {
    material: {},
    lesson: {},
    homework: { type: Boolean },
  },
  computed: {
    currentSlides() {
      return this.slides[this.slidemode]
    },
    host() {
      let user = this.$store.state.user
      if(user.primary_role_id == 3) {
        return true
      }
      let course = this.material.course
      if(!course) {
        return false
      }
      return course.type == 'custom' && (course.instructor_id == user.id || course.school_id == user.id)
    },
  },
  methods: {
    getSlides() {
      LessonsService.getSlidesForMaterial(this.material.id).then(res => {
        this.slides = res.data.data.slides
        this.loaded = true
      })
    },
    slidechanged(slide) {
      this.slide[this.slidemode] = slide
      this.$nextTick(() => {
        this.$refs.presentationpreviewmodal.$refs.modal.scrollTop = 0
        // this.$refs.presentationpreviewmodal.$refs.modal.scrollTo({ top: 0, left: 0, behavior: 'smooth'})
      })
    },
    slidemodechange(mode) {
      this.slidemode = mode
    },
    homeworkDone() {
      if(!this.lesson) { return }
      let params = {
        lesson_id: this.lesson.id,
      }
      StudentPageService.homeworkDone(params).then(() => {
        this.$bvModal.hide('presentationpreviewmodal')
      })
    }
  },
  mounted() {
    if(this.homework) {
      this.slidemode = 'homework'
    }
    this.getSlides()
  },

}
</script>

<style scoped lang="scss">


</style>






